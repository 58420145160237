.item{
	display: flex;
	flex-direction: column;
	width: 240px;
	position: relative;

	&:after{
		position: absolute;
		left: -1px;
		top: 0;
		    width: calc(100% + 1px);
		height: 100%;
	    border: 1px solid #d5d9e1;
	    box-shadow: 1px 6px 20px -3px rgba(89, 88, 95, 0.5);
	    opacity: 0;
	    content: "";
	    z-index: -1;
	}
	&:hover{
		&:after{
			opacity: 1;
		}
	}

	&--list{
		flex-direction: column;
		height: auto;
		width: 240px;

		@include media($md){
			flex-direction: row;
			height: 240px;
			width: 100%;
		}
		.item{
			&__descr-text{
				display: none;
				@include media($md){
					display: block;
				}
			}
			&__descr-text{
				order: auto;
				@include media($md){
					order: 2;
				}
			}
			&__descr{
				order: auto;
				width: 100%;
				border-left: none;
				flex-shrink: 0;
				padding: 10px;
				@include media($md){
					order: 3;
					width: 250px;
					border-left: 1px solid $c-lgrey;
					padding: 20px;
				}
			}
			&__image-wrapper{
				border-right: none;
				border-bottom: 1px solid $c-lgrey;
			}
			&__image-wrapper{
				width: 100%;
				flex-shrink: 0;
				order: auto;
				@include media($md){
					width: 240px;
					order: 1;
				}
			}
			&__title{
				position: relative;
				left: 0;
				top: 0;
				width: 100%;
				@include media($md){
					position: absolute;
					left: 260px;
					top: 20px;
					width: calc(100% - 530px);
				}
			}
			&__reviews{
				position: relative;
				left: 0;
				top: 0;
				width: 100%;
				@include media($md){
					position: absolute;
					left: 260px;
					top: auto;
					bottom: 40px;
					width: calc(100% - 530px);
				}
			}
		}
	}

	&__descr{
		display: flex;
		flex-direction: column;
		padding: 20px 10px 10px 10px;
	}
	&__image-wrapper{
		position: relative;
		width: 100%;
		border-top: 1px solid $c-lgrey;
		border-left: 1px solid $c-lgrey;
		border-right: 1px solid $c-lgrey;
		border-bottom: none;
		height: 240px;
		transition: border-color 0.1s ease;

		&:hover{
			border-color: $c-lgrey;
		}
	}
	&__compare{
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		cursor: pointer;
		svg{
			width: 24px;
			height: 22px;
			fill: $c-blue;
		}
		&:hover{
			svg{
				fill: $c-lblue;
			}
		}
		&.is-active{
			svg{
				fill: $c-lblue;
			}
		}
	}
	&__favorites{
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		cursor: pointer;
		svg{
			width: 24px;
			height: 24px;
			fill: $c-blue;
		}
		&:hover{
			svg{
				fill: $c-lblue;
			}
		}
		&.is-active{
			svg{
				fill: $c-lblue;
			}
		}
	}
	&__widgets{
		display: flex;
		width: 70px;
		justify-content: space-between;
		margin-right: 10px;
	}
	&__image{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		padding: 15px;
	}
	&__title{
		height: 52px;
		overflow: hidden;
		font-size: 14px;
		font-weight: 300;
		font-family: 'Museo Sans';
		margin-bottom: 10px;
		text-decoration: none;
		color: $c-blue;
		&:hover{
			color: $c-lblue;
		}
	}
	&__price-row{
		display: flex;
		flex-direction: column;
		height: 40px;
		margin-bottom: 12px;
	}
	&__price{
		font-size: 18px;
		font-family: 'Museo Sans';
		font-weight: 500;
	}
	&__old-price{
		font-size: 14px;
		font-family: 'Museo Sans';
		font-weight: 300;
		color: $c-bgrey;
		text-decoration: line-through;
	}
	&__descr-bottom-row{
		margin-bottom: 16px;
		display: flex;
		justify-content: space-between;
	}
	&__reviews{
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 0.2em;
		font-weight: 300;
		font-family: 'Museo Sans';
		color: $c-blue;
		text-decoration: none;
		&:hover{
			color: $c-lblue;
		}
	}
	&__descr-text{
		display: none;
		font-size: 14px;
		line-height: 18px;
		font-weight: 100;
		width: 100%;
		font-family: 'Museo Sans';
		color: $c-bgrey;
		margin-bottom: 32px;
		max-height: 130px;
		padding: 20px;
		margin-top: 52px;
		overflow: hidden;
		@include media($def){
			max-height: 148px;
		}
	}
	&__labels{
		position: absolute;
		left: 0;
		top: 1px;
		display: flex;
		flex-direction: column;
	    align-items: flex-start;
	}
	&__descr-top-row{
		display: flex;
		justify-content: space-between;
		height: 26px;
		overflow: hidden;
		margin-bottom: 10px;
	}
	&__articul{
		color: $c-bgrey;
	}
	&__status{
		&--not-available{
			color: $c-pink;
		}
		&--on-stock{
			color: $c-teal;
		}
		&--in-stock{
			color: $c-lblue;
		}
	}
	&__articul,
	&__status{
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 300;
		font-family: 'Museo Sans';
	}
	&__label{
		height: 24px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		color: $c-white;
		font-size: 10px;
		font-weight: 300;
		font-family: 'Museo Sans';
		position: relative;
		&:after{
			position: absolute;
			left: 100%;
			height: 100%;
		    border: 10px solid transparent;
		    border-left: 6px solid;
		    border-top: 14px solid;
			top: 0;
			content: "";
		}
		&--stock{
			background-color: $c-tomato;
			&:after{
			    border-left-color: $c-tomato;
			    border-top-color: $c-tomato;
			}
		}
		&--new{
			background-color: blue;
			&:after{
			    border-left-color: blue;
			    border-top-color: blue;
			}
		}
		&--spec{
			background-color: $c-tomato;
			&:after{
			    border-left-color: $c-tomato;
			    border-top-color: $c-tomato;
			}
		}
		&--hit{
			background-color: #039803;
			&:after{
			    border-left-color: #039803;
			    border-top-color: #039803;
			}
		}
	}

	&__wrapper-buy {
		display: flex;
		align-items: center;
		width: 100%;

		.button {
			width: 53%;
			flex-shrink: 0;
			height: 50px;

			.button__content {
				height: 50px;
			}
		}
	}

	&__buy-click {
		white-space: nowrap;
		//width: 48%;
		margin-left: 4%;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: bold;
		font-size: 12px;
		letter-spacing: 0.05em;
		flex-grow: 1;
		color: #005da9;

		&:hover {
			color: #586889;
		}
	}

	&-elements__group--custom {
		@include media($def) {
			width: calc(100% + 30px);
		}
	}

	&-elements__info {
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		z-index: 30;

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border-left: 1px solid #c6d4e6;
			height: 34px;
			display: block;
		}

		&-button {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #586889;
			background-color: #f5f5f8;
			transition: background-color .3s;
			height: 56px;
			cursor: pointer;

			> span {
				text-transform: uppercase;
				font-size: 10px;
				font-weight: 300;
				letter-spacing: 0.09em;

				@include media($md) {
					font-size: 12px;
				}
			}

			&.is-open,
			&:hover {
				background-color: #ebebf1;
			}

			//&:active {
			//	background-color: #c6d4e6;
			//}

			svg {
				fill: #4f8ec4;
				width: 26px;
				height: 16px;
				margin-right: 8px;

				@include media($md) {
					margin-right: 14px;
					width: 30px;
				}
			}
		}

		&-content {
			position: absolute;
			top: calc(100% + 8px);
			left: 0;
			width: 100%;
			border: 1px solid #d5d9e1;
			border-radius: 4px;
			color: #586889;
			padding: 16px;
			background-color: #fff;
			font-size: 12px;
			cursor: default;

			&::before {
				content: '';
				position: absolute;
				bottom: 100%;
				left: 0;
				display: block;
				width: 100%;
				height: 8px;
			}

			ul,
			ol {
				padding-left: 0;
				list-style-position: inside;
			}
		}
	}
}
