._mgb30{
	margin-bottom: 20px;
	@include media($def){
		margin-bottom: 30px;
	}
}
._mgb34{
	margin-bottom: 34px;
}
._mgb14{
	margin-bottom: 14px;
}
._mgb20{
	margin-bottom: 20px!important;
}
._tac{
	text-align: center;
}