.sort-widget{
	display: flex;
	width: 100%;
	background-color: $c-lgrey;
	height: auto;
	align-items: center;
	padding: 10px;
	margin-bottom: 20px;
	flex-wrap: wrap;
	position: relative;
	justify-content: center;
	@include media($ms){
		justify-content: flex-start;
	}
	@include media($md){
		flex-wrap: nowrap;
		height: 50px;
	}
	@include media($def){
		margin-bottom: 30px;
	}
	@include media($lg){
		padding: 0 30px;
	}
	&__sort-widget-group{
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-bottom: 10px;
		@include media($ms){
			margin-bottom: 0;
		}
		@include media($md){
			flex-direction: row;
		}

		&:nth-child(2){
			margin-left: 0;
			margin-right: 0;
			@include media($ms){
				margin-left: auto;
				margin-right: auto;
			}
			@include media($lg){
				margin-left: 66px;
			}
		}
		&:nth-child(3){
			width: 80px;
			right: 10px;
			display: none;
			justify-content: space-between;
			flex-direction: row;
			@include media($md){
				display: flex;
			}
		}
	}
	&__text{
		font-size: 16px;
		color: $c-dark;
		font-weight: 300;
		font-family: 'Museo Sans';
		margin-right: 0;
		margin-bottom: 10px;
		@include media($md){
			margin-right: 10px;
			margin-bottom: 0;
		}
	}
}
.items-mod-type{
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	width: 27px;
	height: 26px;
	cursor: pointer;
	svg{
		width: 27px;
		height: 26px;
		fill: $c-blue;
	}
	&:hover{
		svg{
			fill: $c-lblue;
		}
	}
	&.is-active{
		svg{
			fill: $c-lblue;
		}
	}
}