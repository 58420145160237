.lk-nav{
	width: 268px;
	margin-right: 20px;
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: none;
	@include media($def){
		display: block;
	}
	&__item{
		display: flex;
		width: 100%;
	}
	&__link{
		width: 100%;
		border-bottom: 1px solid $c-omgray;
		color: $c-blue;
		padding-left: 20px;
		font-size: 16px;
		min-height: 34px;
		line-height: 110%;
		display: flex;
		align-items: center;
		font-weight: 300;
		font-family: 'Museo Sans';
		text-decoration: none;
		&.is-active{
			border-color: $c-dblue;
			color: $c-dblue;
		}
		&:hover{
			color: $c-dblue;
		}
	}
}