.to-top {
    // position: fixed;
    // // bottom: 10%;
    // right: auto;
    // left: 5%;
    // top: 0;
    // bottom: 0;
    // margin: auto 0;
    // z-index: 255;
    // justify-content: center;
    // align-items: center;
    // cursor: pointer;
    // transition: transform 0.3s cubic-bezier(1,.15,1,.01), opacity 0.3s ease;
    // border-radius: 100%;
    // display: flex;
    // transform: scale(0);
    // transform-origin: center;
    // backface-visibility: hidden;
    // opacity: 0;

    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    display: none;
    width: 30px;
    height: 30px;
    color: #d5dddf;
    text-align: center;
    cursor: pointer;
    z-index: 255;

    @include media($lg){
        top: 0;
        left: 0;
        height: 100%;
        width: 60px;
    }
    
    &.js-active {
        // transform: scale(1);
        // opacity: 1;
        // transition: transform 0.5s cubic-bezier(0.78, 0.77, 0.74, 1.15), opacity 0.5s ease;
        display: block;
    }
    &:hover{
        background: #f5f7f8;
        color: #a6b5ba;
    }
    .to-top__wrapper{
        // background-color: $c-blue;
        // border-radius: 100%;
        // transition: background-color 0.5s ease,transform 0.2s ease;
        // justify-content: center;
        // align-items: center;
        // display: flex;
        // position: relative;
        // width: 50px;
        // height: 50px;
        // transform-origin: center;

        &:hover{
            // transform: scale(0.9);
            // background-color: lighten($c-blue, 10%);
        }
    }
    &__image{
        // fill: $c-white;
        // stroke: $c-white;
        // max-width: 50%;
        // max-height: 50%;

            position: relative;
            top: 50%;
            margin-top: -15px;
            width: 30px;
            height: 30px;
            display: inline-block;
            background-color: transparent;
            color: inherit;
            vertical-align: middle;
            fill: currentColor;
            stroke: currentColor;
            stroke-width: 0;
            pointer-events: none;
    }
}
.to-top__border {
    // border: 2px solid $c-blue;
    // background-color: transparent;
    // border-radius: 100%;
    // position: absolute;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 100%;
    // display: block;
    // transition: transform 0.5s ease;
    // &:hover{
    //     animation: arrow-to-top 1s linear infinite;
    // }
}
// @keyframes arrow-to-top {
//     0% {
//         opacity: 1;
//         transform: scale(1);
//     }

//     50% {
//         opacity: 1;
//         transform: scale(1.15);
//     }

//     100% {
//         opacity: 0;
//         transform: scale(1.3);
//     }
// }
