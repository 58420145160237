////
/// @group _partials/breadcrumbs.scss
////

// scss
// ================================

.breadcrumbs {
	width: 100%;
	display: flex;
	line-height: 0;
	margin: 0;
	font-size: 10px;
	align-items: center;
	height: 40px;
	border-bottom: 1px solid $c-grey3;
	margin-bottom: 50px;
	//flex-wrap: wrap;
}

.breadcrumbs a {
	font-size: 10px;
	color: $c-blue;
	letter-spacing: 0.01em;
	font-weight: 300;
	font-family: 'Museo Sans';
	text-decoration: underline;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 230px;
	&:hover{
		color: $c-lblue;
	}
}

.breadcrumbs span {
	color: $c-blue;
	display: inline-flex;
	vertical-align: top;
	font-size: 10px;
	line-height: 1.4;
	margin-right: 6px;
	letter-spacing: 0.01em;
	font-weight: 300;
	font-family: 'Museo Sans';
	text-transform: uppercase;

	&:before {
		margin-right: 10px;
		content: '-';
		display: inline-block;
		vertical-align: top;
	}

	&:first-child:before {
		display: none;
	}

	&[typeof="v:Breadcrumb--current"] {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 230px;
	}
}

.breadcrumbs {
	@include media( $md, max ) {
		display: flex;

		span {
			&, &:before {
				display: none;
			}

			&:nth-last-child(2) {
				display: block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 100%;
				background: none;
					margin: 0 5px 0 3px;

				&:before {
					content: '<';
					display: inline-block;
				}
			}
		}
	}
}
