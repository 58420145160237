.search-form{
	display: flex;
	position: fixed;
	width: 100%;
	left: 100%;
	top: 70px;
	transition: right 0.1s ease;
	@include media($def){
		position: relative;
		width: 56%;
		max-width: 330px;
		top: 0;
		left: 0;
	}
	&.is-active{
		left: 0;
	}
	.form__group{
		width: 100%;
		margin-bottom: 0;
	}
	&__btn{
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto 0;
		width: 42px;
		height: 42px;
		background-color: transparent;
		border: none;
		outline: none;
		svg{
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			fill: $c-blue;
			width: 22px;
			height: 22px;
			transition: fill 0.1s ease;
		}
		&:hover{
			svg{
				fill: $c-lblue;
			}
		}
	}
}