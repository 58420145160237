////
/// @group _partials/view-wrapper.scss
////


// scss
// ================================

.view-wrapper {
	position: relative;
	min-height: 100%;
	overflow: hidden;
	padding-top: 70px;
	@include media($def){
		padding-top: 0;
	}
}