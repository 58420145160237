////
/// @group _partials/view-section.scss
////


// scss
// ================================

.view-section {
	position: relative;
	background-color: $c-white;
	z-index: 100;
	padding-bottom: 10px;
	@include media($def){
		padding-bottom: 20px;
	}
}
