.hidden-gllery{
	display: flex;
	width: 100%;
	margin: 0 auto;
	flex-direction: row;
	justify-content: center;
	height: 100%;
	padding: 6px;

	@include media($def){
		margin: 0;
	}
	&__thumbs-wrapper-wrapper{
		position: relative;
		display: flex;
		align-items: center;
	}
	&__main-image{
		width: 100%;
		height: 100%;
		position: relative;
		margin-left: 0;
		border-left: 1px solid $c-grey;
		order: 1;
		cursor: pointer;
		margin-top: auto;
		margin-bottom: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		@include media($def){
			order: 2;
			margin-left: 8px;
		}

		img{
			max-width: 100%;
			margin: auto;
			max-height: 100%;
		}
	}
	&__thumbs-wrapper{
		position: relative;
		width: 76px;
		height: 300px;
		order: 1;
		flex-shrink: 0;
		@include media($xs){
			height: 100%;
		}
	}
	&__thumb{
		transform: rotate(0);
		position: relative;
		width: 65px!important;
		height: 65px !important;
		border: 1px solid #d5d9e1;
		display: inline-flex;
		justify-content: center;
		align-content: center;
		cursor: pointer;
		transition: border-color 0.1s ease;

		&.is-active{
			border-color: $c-lblue;
			pointer-events: none;
		}

		&--3d{
			.hidden-gllery{
				&__thumb-decor{
					width: 65px;
					height: 25px;
				}
			}
		}
		&--video{
			.hidden-gllery{
				&__thumb-decor{
					width: 24px;
					height: 17px;
					bottom: 12px;
					background-color: rgba(255,255,255, 0.5);
				}
			}
		}
	}
	.swiper-button-prev,
	.swiper-button-next{
		bottom: 0;
	}
	&__thumb-decor{
		position: absolute;
		bottom: 6px;
		left: 0;
		right: 0;
		margin: 0 auto;
		z-index: 20;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
	}
	&__thumbs{
		height: 300px;
		width: 66px;
		position: relative;
		left: 0;
		top: 0;
		@include media($xs){
			height: 414px;
		}
	}
}

.js-gallery-3d-sprite-image{
	cursor: move;
	width: 180px;
	height: 180px;
	@include media($md){
		width: 500px;
		height: 500px;
	}
}

.js-gallery-simple-image,
.js-gallery-3d-sprite-image{
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	opacity: 0;
	transition: opacity 0s ease;
	z-index: 0;
	&.js-visible{
		z-index: 1;
		opacity: 1;
		transition: opacity 0s ease;
	}
	&.js-changing{
		z-index: 1;
		opacity: 0;
		transition: opacity 0s ease;
	}
}
.js-gallery-video{
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	opacity: 0;
	transition: opacity 0s ease;
	z-index: 0;
	&.js-visible{
		z-index: 1;
		opacity: 1;
		transition: opacity 0s 0.3s ease;
	}
	&.js-changing{
		z-index: 1;
		opacity: 0;
		transition: opacity 0s ease;
	}
}

@keyframes change_slide {
    from {opacity: 0;}
    to {opacity: 1;}
}
