.reviews-grid{
	display: flex;
	flex-direction: column;
	width: 100%;
}
.review-item{
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 22px;
	padding-bottom: 22px;
	position: relative;
	&:after{
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		background-color: $c-lblue;
		height: 1px;
		content: "";
	}
	&__top-row{
		margin-bottom: 18px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		@include media($md){
			flex-direction: row;
			align-items: center;
		}
	}
	&__title{
		font-size: 18px;
		line-height: 110%;
		color: $c-black;
		font-weight: 300;
		font-family: 'Museo Sans';
		margin-right: 14px;
	}
	&--admin{
		padding-left: 20px;
		padding-right: 20px;
		&:after{
			display: none;
		}
	}
	&__text{
		margin-bottom: 20px;
		font-size: 16px;
		line-height: 110%;
		font-weight: 300;
		font-family: 'Museo Sans';
	}
	&__date{
		color: $c-lblue;
		font-weight: 300;
		letter-spacing: 0.2em;
		font-family: 'Museo Sans';
		font-size: 12px;
	}
}