.main-nav{
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: none;
	@include media($def){
		display: flex;
	}
	&__item{
		padding: 0;
		&:last-of-type{
			padding-right: 0;
		}
	}
	&__link{
		text-decoration: none;
		color: $c-blue;
		padding: 10px 12px;
		position: relative;
		font-size: 15px;
		line-height: 24px;
		font-weight: 300;
		font-family: 'Museo Sans';
		display: flex;
		align-items: center;
		height: 50px;

		&:hover{
			color: #005da9;
			background-color: #ebebf1;
		}
		&:after{
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background-color: $c-dblue;
			transition: opacity 0.1s ease;
			opacity: 0;
			content: "";
			height: 1px;
		}
		&.is-active{
			pointer-events: none;
			color: $c-dblue;
			&:after{
				opacity: 1;
			}
		}
	}
}
