////
/// @group _partials/title.scss
////


// scss
// ================================

.title {
	line-height: 1.2;
	font-weight: bold;
	color: $color-gray75;
	font-size: 2rem;
	margin: 1.2em 0 1rem;

	&:first-child {
		margin-top: 0;
	}
}

// размеры
.title {
	&--sup {
		font-size: 2.5rem;
	}

	&--sub {
		font-size: 1.5rem;
	}
}
