
.feed-block{
	padding: 20px 0;
	border-bottom: 1px solid $c-grey;
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: column;
	margin-bottom: 20px;
	
	@include media($def){
		flex-direction: row;
	}

	&__form-wrapper{
		width: 100%;
		padding-left: 0;
		display: flex;
		align-items: center;
		@include media($lg){
			padding-left: 7.8%;
		}
		@include media($def){
			width: calc(100% - 420px);
			padding-left: 2%;
		}
	}
	&__form{
		display: flex;
		align-items: center;
		align-content: center;
		width: 100%;
		justify-content: space-between;
		flex-direction: column;
		@include media($sm){
			flex-direction: row;
		}
		.form__group{
			margin-bottom: 20px;
			width: 100%;
			@include media($sm){
				width: calc(100% - 200px);
				margin-bottom: 0;
			}
		}
	}
	&__text{
		width: 100%;
		font-size: 16px;
		line-height: 18px;
		color: $c-dark;
		font-family: 'Museo Sans';
		font-weight: 300;
		margin-bottom: 20px;
		text-align: center;
		@include media($def){
			margin-bottom: 0;
			width: 420px;
		}
		@include media($sm){
			font-size: 14.5px;
			text-align: left;
			line-height: 20px;
		}
	}
}