.news{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.news-grid{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 26px;
	justify-content: center;
	@include media($def){
		margin-bottom: 66px;
	}
}

.news-item{
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	max-width: 328px;
	margin: 2px;
	text-decoration: none;
	@include media($md){
		width: 269px;
	}

	&:nth-child(1){
		width: 100%;
		max-width: 328px;

		@include media($def){
			width: 328px;
		}
		@include media($def){
			width: 100%;
			max-width: 100%;
		}

		.news-item{
			&__image{
				height: 266px;
				@include media($md){
					height: 296px;
				}
				@include media($def){
					height: 448px;
				}
			}
			&__decsr{
				position: relative;
				right: auto;
				top: auto;
				width: 100%;
				background-color: $c-peach;
				height: 296px;
				@include media($def){
					position: absolute;
					right: 0;
					top: 0;
					width: 328px;
					height: 100%;
					background-color: rgba(13, 16, 23, 0.9);
				}
			}
			&__text{
				color: $c-blue;
				@include media($def){
					color: $c-white;
				}
			}
		}
		&:hover{
			.news-item{
				&__decsr{
					background-color: $c-lgrey;
					@include media($md){
						background-color: $c-black;
					}
				}
				&__text{
						color: $c-black;
					@include media($md){
						color: $c-lblue;
					}
				}
			}
		}
	}

	&__image{
		width: 100%;
		height: 266px;
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		@include media($md){
			height: 296px;
		}
	}
	&__decsr{
		background-color: $c-peach;
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 12px;
		height: 240px;
		transition: background-color 0.1s ease;
		@include media($md){
			padding: 22px;
		}
	}
	&__date{
		font-size: 12px;
		letter-spacing: 0.2em;
		font-family: 'Museo Sans';
		font-weight: 300;
		color: $c-lblue;
		@include media($md){
			font-size: 9px;
		}
	}
	&__text{
		position: relative;
		padding-top: 12px;
		color: $c-blue;
		font-family: 'Museo Sans';
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		max-height: 254px;
		overflow: hidden;
		transition: color 0.1s ease;
		margin-bottom: 30px;
		@include media($md){
			font-size: 15px;
		}

		&:before{
			position: absolute;
			left: 0;
			top: 0;
			width: 20px;
			height: 1px;
			background-color: $c-lblue;
			content: "";
		}
	}
	&:hover{
		.news-item{
			&__text{
				color: $c-black;
			}
			&__decsr{
				background-color: $c-lgrey;
			}
		}
	}
}