.footer-contacts{
	display: flex;
	flex-direction: column;
	display: none;
	@include media($def){
		display: block;
	}
	&__item{
		margin-bottom: 16px;
		&:last-of-type{
			margin-top: 8px;
		}
	}
	&__link{
		color: $c-blue;
		text-decoration: none;
		font-size: 13px;
		line-height: 100%;
		font-weight: 300;
		font-family: 'Museo Sans';
		&--mail{
			text-decoration: underline;
		}
	}
}