.index-reviews{
    &__item{
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        margin-bottom: 10px;
        position: relative;
        &:after{
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 1px;
            background-color: $c-lblue;
            content: "";
        }
    }
    &__item-title{
        margin-bottom: 16px;
        color: $c-blue;
        font-weight: 300;
        font-size: 15px;
        line-height: 100%;
        text-decoration: none;
        font-family: 'Museo Sans';
        &:hover{
            color: $c-black;
        }
        @include media($sm) {
            font-size: 16px;
        }
    }
    &__rev-name{
        font-size: 14px;
        font-weight: 300;
        font-family: 'Museo Sans';
        color: $c-black;
        @include media($sm) {
            font-size: 12px;
        }
    }
    &__item-date{
        font-weight: 300;
        font-family: 'Museo Sans';
        font-size: 11px;
        margin-left: 6px;
        letter-spacing: 0.2em;
        color: $c-lblue;
        @include media($sm) {
            font-size: 9px;
        }
    }
    &__item-descr{
        display: flex;
        margin-bottom: 12px;
    }
    &__review{
        font-family: 'Museo Sans';
        font-size: 14px;
        font-weight: 300;
        color: $c-black;
        line-height: 24px;
        @include media($sm) {
            font-size: 12px;
        }
    }
}