.page404-wrapper{
	position: relative;
	padding-top: 60px;
}
.page404{
	&__bg-code{
		position: absolute;
		font-size: 150px;
		font-weight: 300;
		color: #f3f6fa;
		left: 0;
		right: 0;
		top: 74px;
		margin: 0 auto;
		text-align: center;
		line-height: 100%;
		@include media($ms){
			font-size: 250px;
			top: 204px;
		}
		@include media($defp){
			font-size: 500px;
		}
	}
	&__error{
		color: $c-black;
		font-size: 26px;
		font-weight: 300;
		line-height: 40px;
		margin-bottom: 20px;
		text-align: center;
		@include media($defp){

			font-size: 30px;
		}
	}
	&__text{
		color: $c-blue;
		font-size: 18px;
		line-height: 24px;
		font-weight: 300;
		text-align: center;
		margin-bottom: 30px;
		@include media($defp){
			margin-bottom: 50px;
		}
	}
	&__title{
		margin-bottom: 20px;
		font-size: 24px;
		font-weight: 300;
		color: $c-black;
		text-align: center;
		@include media($defp){

		margin-bottom: 40px;
		}
	}
	&__subtext{
		font-size: 14px;
		color: $c-black;
		font-weight: 300;
		margin-bottom: 12px;
		text-align: center;
	}
	&__button{
		margin-bottom: 20px;
		@include media($defp){

		margin-bottom: 32px;
		}
	}
}
.page404-content{
	margin-bottom: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
		@include media($defp){
			
	margin-bottom: 120px;
		}
}