.items-tabs{
    &__button{
        color: $c-blue;
        font-size: 30px;
        font-weight: 300;
        line-height: 100%;
        margin: 0 18px;
        font-family: 'Museo Sans';
        cursor: pointer;
        transition: color 0.1s ease;
        position: relative;
        padding-bottom: 14px;
        margin-bottom: 10px;
        @include media($md){
            margin-bottom: 0;
        }
        &:after{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $c-black;
            content: "";
            opacity: 0;
        }
        &:first-of-type{
            margin-left: 0;
        }
        &:last-of-type{
            margin-right: 0;
        }
        &.is-active{
        color: $c-black;
            &:after{
                opacity: 1;
            }
        }
        &:hover{
            color: $c-black;
        }
    }
    &__buttons{
        margin-bottom: 36px;
        flex-wrap: wrap;
        display: none;

        @include media($md){
            display: flex;
        }
    }
    &__button-wrapper{
        display: flex;
        justify-content: center;
    }
    .resp-tabs-buttons__button{
        font-size: 22px;
        margin: 0;
    }
}
.items-tabs-wrapper{
    padding-bottom: 42px;
    border-bottom: 1px solid $c-grey3;
    margin-bottom: 48px;
}