.advantages{
	display: flex;
	margin: auto 0;
	justify-content: space-between;
	flex-wrap: wrap;
	@include media($md){
		flex-wrap: nowrap;
	}
}
.advantages-item{
	display: flex;
	height: 80px;
	width: 100%;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	
	@include media($sm){
		width: 50%;
	}
	@include media($md){
		width: 32%;
		justify-content: flex-start;
	}

	&:last-child {
		.advantages-item {
			&__image {
				fill: transparent;
				stroke: #586889;
				stroke-width: 7px;
				max-width: 44px;
				max-height: 46px;
				left: 37%;

				@include media(lg) {
					left: 48%;
				}
			}
		}

		&:hover {
			.advantages-item {
				&__image {
					fill: transparent;
					stroke: #fff;
				}
			}
		}
	}

	&__image-block{
		//width: 54px;
		height: 80px;
		width: auto;
		min-width: 52px;
		padding-left: 15px;
		margin-right: 10px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		@include media($lg){
			margin-right: 25px;
		}
	}

	&__image{
		max-width: 36px;
		max-height: 36px;
		fill: $c-blue;
		z-index: 5;
		position: relative;
		left: 53%;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	&__image-bg{
		width: 54px;
		height: 80px;
		stroke: $c-lblue;
		fill: transparent;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 4;
		margin: auto;
		@include media($lg){
			width: 70px;
		}
	}
	&__text{
		font-size: 12px;
		text-transform: uppercase;
		color: $c-blue;
		line-height: 15px;
		font-weight: 500;
		font-family: 'Museo Sans';
		width: 90px;
		@include media($lg){
			width: 140px;
			font-size: 11px;
			line-height: 13px;
		}
	}
	&:hover{
		.advantages-item{
			&__text{
				color: $c-lblue;
			}
			&__image{
				fill: $c-white;
			}
			&__image-bg{
				fill: $c-lblue;
			}
		}

	}


}
.advantages--wide-block {
	.advantages-item {
		&__image {
			left: 53%;
		}
	}
}
@media screen and (max-width: 1279px) {

	.advantages-item {
		&__image {
			left: 30.5%;
		}
	}

	.advantages--wide-block {
		.advantages-item {
			&__image {
				left: 30.5%;
			}
		}
	}

}