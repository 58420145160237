.mfp-close{
	width: 62px!important;
	height: 62px!important;
	border-radius: 100%!important;
	border: 1px solid $c-grey3!important;
	display: flex!important;
	justify-content: center!important;
	align-items: center!important;
	align-content: center!important;
	background-color: $c-white!important;
	top: 0!important;
	right: 0!important;
	opacity: 1!important;
	color: $c-blue!important;
	transition: color 0.1s ease, border-color 0.1s ease, transform 2s ease!important;

	@include media($lg){
		top: -30px!important;
		right: -30px!important;
	}

	&:hover{
		border-color: $c-lblue!important;
		color: $c-lblue!important;
	}
	&:active{
		transform: scale(0.92)!important;
		transition: color 0.1s ease, border-color 0.1s ease, transform 0s ease!important;
	}
}