.user-space {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 80px;

	&__item {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 14px;
		margin-bottom: 20px;
		text-decoration: none;

		@include media($md, max) {
			@include media($psw) {
				width: 33.3%;
			}

			@include media($ms) {
				width: 25%;
			}
		}

		@include media($md){
			width: 20%;
		}

		.no-touchevents &:hover {
			.user-space__item-text {
				color: #005da9;
			}

			.user-space__item-svg {
				opacity: 1;
				transform: scale(1.1);
			}
		}

		&-svg {
			fill: #005da9;
			max-width: 120px;
			max-height: 120px;
			margin-bottom: 12px;
			opacity: .7;
			transition: opacity .3s, transform .3s;

			svg {
				max-width: 120px;
				max-height: 120px;
				width: 100%;
			}
		}

		&-text {
			text-transform: uppercase;
			color: #586889;
			text-align: center;
			font-weight: bold;
			transition: color .3s;
		}
	}
}
