.logo{
	align-items: center;
	position: fixed;
	margin: 0 auto;
	left: 78px;
	right: 340px;
	top: 10px;
	z-index: 25;
	display: none;
	max-width: 60px;
	@include media($sm){
		top: 2px;
		// display: flex;
		max-width: 366px;
	}
	@include media($def){
		position: relative;
		display: flex;
		max-width: 290px;
		top: 0;
		left: 0;
		right: 0;
		margin: 0;
	}
	img{
		max-width: 60px;
		@include media($def){
			max-width: 290px;
		}
	}
	&__image{
		margin-right: 6px;
		svg{
			fill: $c-dblue;
			width: 58px;
			height: 50px;
			@include media($ms){
				width: 74px;
				height: 68px;
			}
			@include media($def){
				width: 75px;
				height: 87px;
			}
		}
	}
	&__image-text{
		display: none;
		@include media($def){
			display: block;
		}
		svg{
			fill: $c-dblue;
			width: 281px;
			height: 43px;
		}
	}
	&__sub-text{
		color: $c-bgrey;
		font-size: 24px;
		letter-spacing: 0.05em;
		font-weight: 700;
		font-style: italic;
		font-family: 'Museo Sans';
		position: absolute;
		right: 30px;
		bottom: -6px;
	}
	&__text{
		width: 100%;
		font-size: 10px;
		color: $c-bgrey;
		line-height: 14px;
		letter-spacing: 0.1em;
		font-weight: 300;
		font-family: 'Museo Sans';
		text-transform: uppercase;
		margin-bottom: 20px;
		text-align: center;
		margin-left: 0;
		@include media($def){
			margin-bottom: 0;
			text-align: left;
		}
		@include media($lg){
			width: 100%;
			max-width: 240px;
			margin-left: 30px;
		}
	}
	&--footer{
		display: none;
		@include media($def){
			display: flex;
		}
		.logo__image{
			svg{
				width: 60px;
				height: 69px;
			}
		}
		.logo__image-text{
			svg{
				width: 223px;
				height: 34px;
			}
		}
		img {
			@include media($def){
				max-width: 246px;
			}
		}
	}
}
.logo-wrapper{
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	@include media($lg){
		flex-direction: row;
		align-items: center;
	}
}