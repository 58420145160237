
////
/// @group _partials/view-header.scss
////


// scss
// ================================

.view-header {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	z-index: 125;
	height: 70px;
	background-color: $c-white;
	@include media($def){
		position: relative;
		height: auto;
	}
	&__top{
		background-color: $c-lgrey;
		height: 36px;
		align-items: center;
		display: none;
		z-index: 16;
		position: relative;
		@include media($def){
			display: flex;
		}
	}
	&__content{
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 10;
		height: 0;

		@include media($def){
		    height: auto;
		}

		&--left {
			justify-content: flex-start;
		}
	}
	.view-size{
		position: relative;
		z-index: 10;
	}
	&__middle{
		padding: 0;
		height: 0;
		display: flex;
		align-items: center;
		background-color: $c-white;
		position: relative;
		z-index: 25;
		@include media($def){
			height: 112px;
		}
	}
	&__mail-block{
		display: flex;
		align-items: center;
		svg{
			margin-right: 10px;
			fill: $c-blue;
			width: 21px;
			height: 14px;
		}
		color: $c-blue;
		font-size: 14px;
		font-weight: 300;
		letter-spacing: 0.05em;
		margin-left: auto;
		font-family: 'Museo Sans';

		@include media($lg){
			margin-left: 10%;
		}
		&:hover{
			svg{
				fill: $c-lblue;
			}
		}
	}
	&__items-group{
		display: flex;
		flex-direction: row;
		align-items: center;
		max-width: 550px;
		width: 100%;
	    top: 0;
		position: relative;
		justify-content: space-around;
		height: 0;

		@include media($def){
			height: auto;
		}
	}
	&__text{
		color: $c-blue;
		text-transform: uppercase;
		font-size: 10px;
		font-family: 'Museo Sans';
		letter-spacing: 0.1em;
		font-weight: 300;
		margin-right: auto;

		@include media($lg){
			margin-right: 6.5%;
		}
	}
	&__enter-reg{
		display: flex;
		color: $c-blue;
		text-transform: uppercase;
		font-size: 10px;
		font-family: 'Museo Sans';
		align-items: center;
		letter-spacing: 0.18em;
		font-weight: 300;
		transition: color 0.1s ease;
		cursor: pointer;
		margin-left: auto;
		text-decoration: none;
		svg{
			margin-left: 20px;
			width: 10px;
			height: 14px;
			fill: $c-blue;
		}
		&:hover{
			color: $c-lblue;
			svg{
				fill: $c-lblue;
			}
		}
	}
	&__bottom{
		background-color: $c-lgrey;
		height: 70px;
		display: flex;
		align-items: center;
		position: relative;
		@include media($def) {
			height: 50px;
		}
		.view-size {
			z-index: 21;
		}
	}
}
