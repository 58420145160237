.news-reviews-block{
    padding-bottom: 34px;
    border-bottom: 1px solid $c-grey3;
    margin-bottom: 48px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @include media($defp){
    	flex-direction: row;
    	align-items: flex-start;
    	justify-content: space-between;
    }
    &__news{
		width: 100%;
		max-width: 660px;
		padding-right: 10px;
    	flex-shrink: 0;
    	margin-bottom: 20px;
    	@include media($defp){
    		margin-bottom: 0;
    	}
    	.page-title{
    		text-align: center;
    		@include media($defp){
    			text-align: left;
    		}
    	}
    }
    &__reviews{
    	width: 100%;
    	max-width: 100%;
    	padding-left: 0;
    	@include media($defp){
    		max-width: 520px;
    		padding-left: 10px;
    	}
    }
}