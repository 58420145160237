.footer-adres-block{
	display: flex;
	flex-direction: column;
	&__item{
		margin-bottom: 40px;
		&:last-of-type{
			margin-bottom: 0;
		}
	}
	&__title,
	&__text{
		font-size: 13px;
		line-height: 18px;
		color: $c-blue;
		font-family: 'Museo Sans';
	}
	&__title{
		font-weight: 500;
		margin-bottom: 10px;
	}
	&__text{
		font-weight: 300;
	}
}