.item-page{
	&__info{
		display: flex;
		margin-bottom: 20px;
		flex-direction: column;
		justify-content: space-between;
		@include media($defp){
			flex-direction: row;
		}
	}
	&__bottom-row{
		padding-bottom: 24px;
		border-bottom: none;
		margin-bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		@include media($defp){
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
		}
	}
	&__feeds-block{
		width: 100%;
		padding-right: 0;
		margin-bottom: 20px;
		@include media($defp){
			width: calc(100% - 344px);
			margin-bottom: 0;
			padding-right: 6.3%;
		}
	}
	&__review-title{
		min-height: 52px;
		display: flex;
		align-items: center;
		margin-right: 30px;
		font-size: 19px;
		line-height: 100%;
		font-weight: 300;
		font-family: 'Museo Sans';
		color: $c-black;
		border-bottom: 1px solid $c-blue;
		margin-bottom: 38px;
	}
	&__review{
		width: 100%;
		padding-bottom: 40px;
		@include media($defp){
			width: 344px;
			height: 550px;
		}
	}
}

.similar-items{
	display: flex;
	flex-direction: column;
	&__title{
		font-size: 22px;
		color: $c-black;
		line-height: 100%;
		font-weight: 300;
		margin-bottom: 34px;
		font-family: 'Museo Sans';
	}
}