.js-restructure-grid{
	opacity: 1;
	transition: opacity 0s ease;
	&.js-restructuring{
		opacity: 1;
	}
}
.js-items-restructure{
	&.is-active{
		user-select: none;
		pointer-events: none;
	}
}