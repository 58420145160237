.item-slider{
	display: flex;
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
	flex-direction: row;
	justify-content: flex-start;
	max-height: 220px;
	@include media($xs) {
		max-height: 340px;
	}
	@include media($def){
		width: 540px;
		margin: 0;
	}
	&__main-image{
		width: 100%;
		height: 210px;
		max-width: 350px;
		position: relative;
		margin-left: 0;
		order: 1;
		cursor: pointer;
		&--streak {
			width: 100%;
			height: auto;
			max-width: 350px;
			position: relative;
			margin-left: 0;
			order: 1;
			cursor: pointer;
			&::before {
				content: "";
				height: 100%;
				width: 1px;
				background: #d5d9e1;
				position: absolute;
				left: 0;
				z-index: 1;
			}
		}
		@include media($xs){
			height: 330px;
		}
		@include media($def){
			order: 2;
			margin-right: 6px;
			width: 330px;
		}

		img{
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			max-height: 100%;
		}
	}
	&__thumbs-wrapper{
		position: relative;
		width: 76px;
		height: 100%;
		max-height: 290px;
		order: 1;
		flex-shrink: 0;
	}
	&__thumbs-slider-wrapper{
		// width: 460px;
		// height: 66px;
		// position: relative;
		// transform-origin: 0 100%;
		// transform: rotate(90deg);
		// @include media($def){
		// 	width: 460px;
		// 	height: 66px;
		// 	position: absolute;
		// 	left: 0;
		// 	top: 0;
		// 	transform: rotate(90deg) translateX(-68px);
		// }
	}
	&__thumb{
		transform: rotate(0);
		position: relative;
		width: 65px!important;
		height: 65px;
		border: 1px solid #d5d9e1;
		display: inline-flex;
		justify-content: center;
		align-content: center;
		cursor: pointer;

		&--3d{
			.item-slider{
				&__thumb-decor{
					width: 65px;
					height: 25px;
				}
			}
		}
		&--video{
			.item-slider{
				&__thumb-decor{
					width: 24px;
					height: 17px;
					bottom: 12px;
					background-color: rgba(255,255,255, 0.5);
				}
			}
		}
	}
	&__thumb-decor{
		position: absolute;
		bottom: 6px;
		left: 0;
		right: 0;
		margin: 0 auto;
		z-index: 20;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
	}
	&__thumbs{
		height: 300px;
		width: 66px;
		position: relative;
		left: 0;
		top: 0;
		@include media($xs){
			height: 414px;
		}
	}
}
