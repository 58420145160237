.header-widgets{
	display: flex;
	position: fixed;
	// top: 4px;
	z-index: 25;
	right: 22px;
	// flex-wrap: wrap;
	// max-width: 150px;
	// @include media($sm){
		flex-wrap: nowrap;
		top: 20px;
		max-width: initial;
	// }
	@include media($def){
		top: 0;
		right: 0;
		position: relative;
	}
	&__search-resp-button{
		// margin-left: 0;
		cursor: pointer;
		display: flex;
		justify-content: center;
		// width: 50%;
		// padding-right: 18px;
		// margin-bottom: 2px;
		// @include media($sm){
			width: auto;
			margin-left: 20px;
			padding-right: 0;
			margin-bottom: 0;
		// }
		@include media($def){
			display: none;
		}
	}
	&__image{
		fill: $c-blue;
		transition: fill 0.1s ease;
		width: 24px;
		height: 30px;
		@include media($xs){
			width: 30px;
		}
	}
	&__item{
		position: relative;
		margin: 0;
		height: 30px;
		cursor: pointer;
		padding-right: 12px;
		// width: 50%;
		display: flex;
		// justify-content: center;
		// margin-bottom: 2px;
			justify-content: flex-start;
			width: auto;
			margin: 0 0 0 8px;

		@include media($xs){
			padding-right: 18px;
		}
		@include media($sm){
			margin: 0 0 0 18px;
		}
		@include media($def) {
			margin: 0;
		}
		&:hover{
			.header-widgets{
				&__counter{
					color: $c-lblue;
				}
				&__image{
					fill: $c-lblue;
				}
			}
		}
		&.is-active{
			.header-widgets{
				&__counter{
					color: $c-lblue;
				}
				&__image{
					fill: $c-lblue;
				}
			}
		}
	}
	&__counter{
		color: $c-blue;
		transition: color 0.1s ease;
		font-size: 16px;
		font-weight: 700;
		font-family: 'Museo Sans';
		position: absolute;
		bottom: -4px;
		right: -7px;
		min-width: 18px;
		@include media($xs){
			right: 0;
		}
	}
}

.js-filter-handler{
	display: flex;
	@include media($def){
		display: none;
	}
}