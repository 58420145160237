.sizes-table-wrapper{
	display: flex;
	flex-direction: column;
	margin-right: 0;
	width: 100%;
	background-color: $c-white;
	overflow: overlay;
	margin-bottom: 10px;
	@include media($defp){
		margin-bottom: 0;
		margin-right: 16px;
		width: 100%;
	}
	@include media($lg){

	}
}

.sizes-table{
	border-spacing: 0;
	border: 1px solid $c-grey;
	margin: 0 auto;
	min-width: 280px;
	width: 100%;
	&__link{
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 40px;
		padding: 4px;
		text-decoration: none;
		color: $c-blue;
		font-size: 14px;
		line-height: 110%;
		font-family: 'Museo Sans';
		transition: color 0.1s ease, background-color 0.1s ease;
		flex-direction: column;
	}

	&__text{
		font-size: 16px;
		line-height: 110%;
		color: $c-black;
		font-family: 'Museo Sans';
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 40px;
		padding: 10px;
	}
	
	&__price{
		margin-bottom: 4px;
		white-space: nowrap;
	}
	&__old-price{
	    text-decoration: line-through;
	    color: $c-grey;
	    font-size: 12px;
	    white-space: nowrap;
	}

	&__row{
		transition: background-color 0.1s ease;
		&:hover{
			.sizes-table{
				&__link{
					color: $c-lblue;
					background-color: lighten($c-grey, 10%);
				}
			}
		}
	}

	&__th,
	&__td{
		border: 1px solid $c-grey;
	}
}