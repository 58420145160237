.resp-tabs-buttons{
	position: relative;
	width: 100%;
	min-height: 48px;
	justify-content: center;
	align-items: center;
	align-content: center;
	display: flex;
	margin-bottom: 20px;
	@include media($md){
		display: none;
	}
	
	
	&:after{
		position: absolute;
		right: 18px;
		top: 0;
		bottom: 0;
		margin: auto 0;
		width: 14px;
		height: 6px;
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		content: "";
		background: url(../pic/arrow_bottom.svg);
		z-index: 15;
	}
	
	&__active,
	&__button{
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		line-height: 100%;
		font-weight: 300;
		font-family: 'Museo Sans';
		color: $c-blue;
		position: relative;
		transition: color 0.3s ease;
		margin-right: 0;
		border-bottom: 1px solid $c-blue;
		background-color: $c-white;
		z-index: 5;
		cursor: pointer;

		&.is-active{
			background-color: lighten($c-grey, 10%);
		}
	}

	&__active{
		width: 100%;
		cursor: pointer;
		z-index: 15;
		position: relative;
	}

	&__content{
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		transform: scale(0.98) translateY(-80px);
		opacity: 0;
		visibility: hidden;
		transition: transform 0.3s 0s ease, opacity 0.3s 0s ease, visibility 0s 0.3s ease;
		z-index: 5;

		&.is-active{
			visibility: visible;
			opacity: 1;
			transform: scale(1);
			transition: transform 0.3s 0.1s ease, opacity 0.3s 0.1s ease, visibility 0s 0s ease;
		}
	}
}