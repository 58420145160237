.succes{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&__tnx{
		font-size: 30px;
		line-height: 110%;
		font-weight: 300;
		color: $c-black;
		margin: 0 auto;
		text-align: center;
	}
	&__grid{
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.succes-block{
	margin-bottom: 20px;
	width: 100%;
	border: 1px solid $c-grey3;
	padding: 18px 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@include media($md){
		padding: 38px 18px;
	}
	@include media($def){
		width: calc(50% - 10px);
		margin-bottom: 50px;
	}

	&__title{
		margin-bottom: 22px;
		font-size: 24px;
		font-weight: 300;
		color: $c-black;
	}

	&__text{
		font-size: 14px;
		color: $c-black;
		font-weight: 500;
		display: inline;
	}
	&__sub-text{
		color: $c-blue;
		font-size: 16px;
		font-weight: 300;
		display: inline;
	}
	&__row{
		margin-bottom: 24px;
		display: block;
	}
}