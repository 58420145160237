.page-video {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 40px;

	@include media($def) {
		margin-bottom: 80px;
	}

	&__item {
		width: 100%;
		//padding: 0 16px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		text-decoration: none;

		@include media($md) {
			margin-right: 32px;
			width: calc(50% - 16px);

			&:nth-child(2n) {
				margin-right: 0;
			}
		}

		//@include media($def) {
		//	width: calc(33.3% - 21px);
		//
		//	&:nth-child(2n) {
		//		margin-right: 32px;
		//	}
		//
		//	&:nth-child(3n) {
		//		margin-right: 0;
		//	}
		//}

		.no-touchevents &:hover {
			.page-video__item-svg {
				transform: translate(-50%, -50%) scale(1.2);
				opacity: 1;
			}

			.page-video__item-title {
				color: #005da9;
			}
		}

		&-svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 3;
			fill: #fff;
			opacity: 0.7;
			width: 20%;
			transition: transform .3s, opacity .3s;
		}

		&-img {
			position: absolute;
			width: 100%;
			height: 100%;
			max-height: 100%;
			max-width: 100%;
			object-fit: cover;
		}

		&-title {
			text-align: center;
			text-transform: uppercase;
			margin-top: 12px;
			color: #586889;
			font-weight: bold;
			transition: color .3s;
		}
	}
}

.ratio {
	width: 100%;
	padding-bottom: 56.25%;
	position: relative;
	overflow: hidden;
}
