.tooltip--item-not-available {
	display: none;
	position: absolute;
	margin-left: 90px;
	padding: 5px;
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	color: #fff;
	z-index: 1;
	&::before {
		border: 7px solid #fff;
		border-right-color: rgba(0, 0, 0, 0.7);
		border-left: 0;
		content: "";
		position: absolute;
		margin-left: -12px;
		margin-top: 1px;
	}
}

.button{
	border: none;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	outline: none;
	background: transparent;
	text-decoration: none;
	cursor: pointer;

	&--main{
		.button__content{
			background-color: $c-lblue;
			color: $c-white;
			letter-spacing: 0.1em;
			font-size: 11.5px;
			text-transform: uppercase;
			line-height: 100%;
			font-weight: 700;
			font-family: 'Museo Sans';
			transition: background-color 0.1s ease;
			border-radius: 4px;
		}
		&:hover{
			.button__content{
				background-color: lighten($c-dblue, 5%);
			}
		}
	}

	&__icon{
		width: 12px;
		height: 12px;
		fill: $c-lblue;
		transition: fill 0.1s ease;
		margin-left: 12px;
	}

	&--buy{
		.button__content{
			background-color: transparent;
			color: $c-lblue;
			letter-spacing: 0.1em;
			font-size: 11.5px;
			text-transform: uppercase;
			line-height: 100%;
			font-weight: 700;
			font-family: 'Museo Sans';
			transition: background-color 0.1s ease, color 0.1s ease;
			border-radius: 4px;
			border: 1px solid $c-lblue;
		}
		&:hover{
			.button__content{
				background-color: $c-lblue;
				color: $c-white;
			}
			.button__icon{
				fill: $c-white;
			}
		}
	}

	&--new-buy{
		.button__content{
			background-color: transparent;
			color: $c-lblue;
			letter-spacing: 0.1em;
			font-size: 11.5px;
			text-transform: uppercase;
			line-height: 100%;
			font-weight: 700;
			font-family: 'Museo Sans';
			transition: background-color 0.1s ease, color 0.1s ease;
			border-radius: 4px;
			border: 1px solid $c-lblue;
		}
		.button__new-buy-image{
			width: 30px;
			height: 30px;
			margin: auto;
			position: relative;
			fill: $c-blue;
		}
		&--nope{
			cursor: default;
			pointer-events: none;

			.button__new-buy-image{
				fill: $c-grey;
			}
			.button__content{
				position: relative;
				border-color: $c-grey;

				&:after{
					transform-origin: left top;
					position: absolute;
					// left: 3px;
					// top: 4px;
					left: 50%;
					top: 50%;
					// width: 100%;
					width: 45%;
					height: 1px;
					color: $c-grey;
					background-color: tomato;
					content: "";
					// transform: rotate(34deg);
					transform: rotate(34deg) translate(-50%, -50%);
				}
				&:before{
					transform-origin: left top;
					position: absolute;
					left: 50%;
					top: 50%;
					width: 45%;
					height: 1px;
					color: $c-grey;
					background-color: tomato;
					content: "";
					transform: rotate(-34deg) translate(-50%, -50%);
				}
			}
		}
		&:hover{
			.button__content{
				background-color: $c-lblue;
				color: $c-white;
			}
			.button__new-buy-image{
				fill: $c-white;
			}
		}
	}
	
	&__content{
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding: 0 10px;
	}

	&--not-available{
		.button__content{
			background-color: transparent;
			color: $c-bgrey;
			border: 1px solid $c-bgrey;
			letter-spacing: 0.05em;
			font-size: 10px;
			text-transform: uppercase;
			line-height: 100%;
			font-weight: 700;
			font-family: 'Museo Sans';
			transition: background-color 0.1s ease;
			text-align: center;
			border-radius: 4px;
		}
		&:hover{
			.button__content{
				background-color: $c-grey3;
			}
		}
	}

	&--disabled{
		pointer-events: none;
	}
	
	&--w100{
		width: 100%;
	}
	&:hover{
		
	}
	&:active{
		.button__content{
			background-color: $c-blue;
			transition: background-color 0s ease;
		}
	}

	&--sub{
		.button__content{
			background-color: $c-bgrey;
			color: $c-white;
			letter-spacing: 0.05em;
			font-size: 10px;
			text-transform: uppercase;
			line-height: 100%;
			font-weight: 700;
			font-family: 'Museo Sans';
			transition: background-color 0.1s ease;
			border-radius: 4px;
		}
		&:hover{
			.button__content{
				background-color: $c-lblue;
			}
		}
	}

	&--sub2{
		.button__content{
			color: $c-lblue;
			border: 1px solid $c-lblue;
			transition: color 0.1s ease, border-color 0.1s ease, transform 0.1s ease;
		}
		&:hover{
			.button__content{
				color: $c-dblue;
				border-color: $c-dblue;
			}
		}
		&:active{
			.button__content{
				transform: scale(0.95);
				color: lighten($c-dblue, 5%);
				border-color: lighten($c-dblue, 5%);
				background-color: transparent;
				transition: color 0.1s ease, border-color 0.1s ease, transform 0s ease;
			}
		}
	}

	&--mgsm{
		min-width: 30px;
		height: 30px;
	}

	&--sm{
		height: 30px;
		display: inline-block;
		.button__content{
			height: 30px;
			min-width: 120px;
		}
	}

	&--mgs{
		height: 30px;
		.button__content{
			height: 30px;
			width: 40px;
		}
	}

	&--sm-f{
		height: 30px;
		display: inline-block;
		.button__content{
			height: 30px;
			width: 120px;
		}
	}

	&--md{
		height: 40px;
		display: inline-block;
		.button__content{
			height: 40px;
			min-width: 180px;
		}
	}

	&--lgmd{
		height: 40px;
		display: inline-block;
		.button__content{
			height: 40px;
			min-width: 260px;
			@include media($lg){
				min-width: 330px;
			}
		}
	}

	&--lg{
		height: 50px;
		display: inline-block;
		.button__content{
			height: 50px;
			min-width: 210px;
			@include media($ms){
				min-width: 250px;
			}
		}
	}
}

.show-all-button{
	text-transform: uppercase;
	color: $c-blue;
	font-weight: 500;
	transition: color 0.1s ease;
	font-size: 13px;
	font-family: 'Museo Sans';
	letter-spacing: 0.1em;
	&:hover{
		color: $c-lblue;
	}
}

.show-more-button{
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: $c-blue;
	text-decoration: underline;
	cursor: pointer;
	transition: color 0.1s ease;
	font-size: 10px;
	letter-spacing: 0.1em;
	font-weight: 500;
	font-family: 'Museo Sans';
	svg{
		width: 14px;
		height: 10px;
		fill: $c-blue;
		margin-left: 10px;
		transition: fill 0.1s ease;
	}
	&:hover{
		color: $c-lblue;
		svg{
			fill: $c-lblue;
		}
	}
}