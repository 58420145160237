.item-tabs{
	width: 100%;
	padding-right: 0;
	margin-bottom: 20px;
	@include media($defp){
		margin-bottom: 0;
	}
	&__buttons{
		display: none;
		flex-wrap: wrap;
		border-bottom: 1px solid $c-blue;
		margin-bottom: 38px;
		@include media($md){
			display: flex;
		}
	}
	&__title{
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 26px;
		font-weight: 300;
		font-family: 'Museo Sans';
		color: $c-blue;
		@include media($def){
			margin-bottom: 30px;
			font-size: 24px;
			line-height: 30px;
		}
		span{
			color: $c-black;
		}
	}
	&__button{
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		line-height: 100%;
		font-weight: 300;
		font-family: 'Museo Sans';
		color: $c-blue;
		position: relative;
		transition: color 0.1s ease;
		margin-right: 0;
		border-bottom: 1px solid $c-blue;
		@include media($md){
			margin-right: 20px;
			border-bottom: 0;
		}
		@include media($lg){
			margin-right: 30px;
			font-size: 19px;
		}
		&:after{
			position: absolute;
			content: "";
			top: 100%;
			height: 1px;
			width: 100%;
			background-color: $c-black;
			left: 0;
			opacity: 0;
		}
		&.is-active{
			color: $c-black;
			&:after{
				opacity: 1;
			}
		}
	}
}