.catalog-nav{
	position: relative;
	width: 28.9%;
	display: none;
	min-width: 300px;
	@include media($def){
		display: flex;
	}
	&__button{
		display: flex;
		padding: 10px 30px 10px 20px;
		font-size: 15px;
		justify-content: space-between;
		line-height: 24px;
		color: $c-lblue;
		background-color: $c-grey2;
		font-weight: 300;
		font-family: 'Museo Sans';
		height: 50px;
		width: 100%;
		align-items: center;
		cursor: pointer;
		z-index: 15;
		user-select: none;
		position: relative;
		transition: background-color 0.1s ease;
		&.is-disabled{
			pointer-events: none;
			cursor: default;
		}
		svg{
			width: 30px;
			height: 22px;
			fill: $c-lblue;
		}
		&:hover{
			background-color: darken($c-grey2, 2%);
		}
		&:active{
			background-color: darken($c-lgrey, 2%);
			transition: background-color 0s ease;
		}
	}
	&__content{
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		transition: visibility 0s 0.1s ease, opacity 0.1s 0s ease, transform 0.1s 0s ease;
		transform: translateY(-400px);
	    border: 1px solid #d5d9e1;

		&.js-active{
			visibility: visible;
			opacity: 1;
			transition: visibility 0s 0s ease, opacity 0.1s 0.1s ease, transform 0.1s 0.1s ease;
			transform: translateY(0);
		}
	}
	&__list{
		background-color: $c-white;
		list-style-type: none;
		padding: 0;
		margin: 0;
		list-style-image: url(data:0);
		box-shadow: 1px 6px 20px -3px rgba(89, 88, 95, 0.1);

		.catalog-nav__list{
			box-shadow: 1px 6px 20px -3px rgba(10, 10, 10, 0.5);
		}
	}
	&__link{
		width: 100%;
		min-height: 34px;
		padding: 7px 10px;
		border-bottom: 1px solid $c-omgray;
		border-top: 1px solid $c-omgray;
		font-size: 13px;
		font-weight: 300;
		font-family: 'Museo Sans';
		color: $c-blue;
		text-decoration: none;
		transition: border-color 0.1s ease, color 0.1s ease;
		position: relative;
		display: flex;
		align-items: center;

		&:hover{
			color: $c-dblue;
			border-color: $c-dblue;
		}

		svg {
			fill: currentColor;
			margin-right: 10px;
			width: 24px;
			height: 18px;
			flex-shrink: 0;
		}
	}
	&__list-item {
		position: relative;

		ul {
			display: none;
			position: absolute;
			left: 100%;
			width: 100%;
			background: #fff;
			list-style: none;
			top: 0;
			padding: 0;
			border-left: 2px solid #efeff4;
			border-right: 2px solid #efeff4;
		}

		&:hover > ul {
			display: block;
		}
	}
}

.list-item__sublist-item {
	position: relative;
	&:hover .list-item__sub-sublist {
		display: block;
	}
}
