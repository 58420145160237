.lk-products{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&__item{
		margin: 16px 20px;
		position: relative;
	}
	&__delete-btn{
		position: absolute;
		right: -16px;
		top: -16px;
		width: 30px;
		height: 30px;
		border: 1px solid $c-grey3;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		color: $c-blue;
		z-index: 10;
		cursor: pointer;
		transition: color 0.1s ease;
		background-color: $c-white;
		z-index: 20;
		user-select: none;
		transition: transform 0.1s ease;
		&:hover{
			color: $c-lblue;
		}
		&:active{
			transform: scale(0.9);
			transition: transform 0s ease;
		}
	}
}