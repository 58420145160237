#m_menu{
	.mm-navbar-top-1{
		height: 66px;
		@include media($ms){
			height: 80px;
		}
	}
	.mm-navbar-top-2{
		display: flex;
		top: 66px;
		border-top: 1px solid $c-grey;
		@include media($ms){
			top: 80px;
		}
	}
	.menu_logo{
		padding: 0;
		display: flex;
		align-items: center;
	}
	.logo__sub-text{
		bottom: 0;
		font-size: 18px;
		right: 10px;
		@include media($xs){
		    font-size: 24px;
		    right: 30px;
		}
	}
	.logo__image-text{
		display: block;
		svg{
			width: 180px;
			height: 32px;

			@include media($xs){
				width: 200px;
				height: 36px;
			}
			@include media($ms){
				width: 281px;
				height: 43px;
			}
		}
	}
	.lng__item{
		color: $c-dblue;
	}
	.view-header__enter-reg{
		color: $c-dblue;
		justify-content: center;
	}
	.mm-navbar-bottom-1{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mm-title{
		color: $c-dblue;
		text-transform: uppercase;
		letter-spacing: 0.025em;
		font-family: 'Museo Sans';
		font-size: 16px;
		font-weight: 500;
	}
	.m-menu__link{
		color: $c-blue;
		font-family: 'Museo Sans';
		font-weight: 300;
		//display: flex;
		//align-items: center;
		//padding-left: 10px;

		//span {
		//	white-space: normal;
		//}
		//
		//svg {
		//	width: 24px;
		//	height: 18px;
		//	fill: currentColor;
		//	margin-right: 10px;
		//	flex-shrink: 0;
		//}
	}
	.mm-panels{
		top: 106px;
		@include media($ms){
			top: 120px;
		}
	}
}
