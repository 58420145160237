.swiper-slider-wrapper-custom{
	position: relative;
	display: flex;
	align-items: center;
}
.swiper-button-next{
	position: absolute;
	top: auto !important;
	bottom: 5px;
	background: none !important;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	right: auto !important;
	left: 0 !important;
	user-select: none;
	width: calc(100% - 12px) !important;
	height: 24px!important;

	svg{
		    width: 40px;
    height: 22px;
		fill: $c-blue;
	}
	&:hover{
		svg{
			fill: $c-lblue;
		}
	}
}
.swiper-button-prev{
	position: absolute;
	bottom: auto !important;
	top: 28px !important;
	background: none !important;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	left: 0 !important;
	right: auto !important;
	width: calc(100% - 12px) !important;
	user-select: none;
	height: 24px!important;

	svg{
		    width: 40px;
    height: 22px;
		fill: $c-blue;
	}
	&:hover{
		svg{
			fill: $c-lblue;
		}
	}
}
.swiper-button-disabled{
	display: none;
}
