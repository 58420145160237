////
/// @group _partials/view-footer.scss
////


// scss
// ================================

.view-footer {
	position: relative;
	bottom: 0;
	left: 0; 
	z-index: 0;
	width: 100%;
	padding-bottom: 14px;
	&__top{
		background-color: #f5f5f8;
		margin-bottom: 16px;
		padding-bottom: 10px;
	}
	&.touch-fix{
		position: relative;
	}
	&__content{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include media($def){
			align-items: flex-start;
			flex-direction: row;
			justify-content: space-between;
		}
		&--bottom{
			// IDI NAHUI SHABLON EBANII
			align-items: center!important;
		}
	}
	&__content-left{
		width: 100%;
		display: flex;
		justify-content: center;
		@include media($def){
			justify-content: flex-start;
			width: calc(100% - 520px);
		}
	}
	&__content-right{
		padding-left: 10px;
		width: 520px;
		display: flex;
		justify-content: space-between;
	}
}
