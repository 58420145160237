.clients{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.clients-item{
	width: 256px;
	position: relative;
	margin-bottom: 20px;
	display: block;
	&__image{
		height: 174px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		margin-bottom: 20px;
		position: relative;
	}
	&__title{
		position: relative;
		font-size: 16px;
		line-height: 20px;
		color: $c-blue;
		font-weight: 300;
		font-family: 'Museo Sans';
		text-decoration: none;
		padding-top: 22px;
		height: 62px;
		overflow: hidden;
		&:after{
			position: absolute;
			left: 0;
			top: 0;
			width: 20px;
			height: 1px;
			background-color: $c-lblue;
			content: "";
			display: none;
		}
	}
	&:hover{
		.clients-item{
			&__title{
				text-decoration: underline;
				color: $c-lblue;
			}
		}
		.catalog-item__overlay{
			opacity: 0;
		}
	}
}