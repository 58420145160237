.page-title{
	font-size: 24px;
	line-height: 100%;
	color: $c-dark;
	font-weight: 300;
	font-family: 'Museo Sans';

	&--single{
		margin-bottom: 20px;
		margin-top: 50px;
	}
}
.page-subtitle{
	font-size: 15px;
	line-height: 24px;
	font-family: 'Museo Sans';
	color: $c-blue;
	font-weight: 300;
	margin-bottom: 42px;
}

.nope-text{
	margin: 16px auto;
	text-align: center;
	width: 100%;
	font-size: 16px;
	font-family: 'Museo Sans';
	color: $c-blue;
	line-height: 24px;
	font-weight: 300;
}