.lk-data{
	display: flex;
	flex-direction: column;
	padding: 20px 0;
	&__group{
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}
	&__title{
		margin-bottom: 20px;
		font-family: 'Museo Sans';
		font-size: 18px;
		color: $c-black;
		font-weight: 300;
	}
	&__text{
		font-family: 'Museo Sans';
		font-size: 16px;
		color: $c-blue;
		font-weight: 300;
	}
}