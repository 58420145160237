////
/// @group _partials/table-wrapper.scss
////



// scss
// ================================


.table-wrapper {
	clear: both;
	display: block;
	position: relative;
	margin: 0;

	&, & *{
	    &::-webkit-scrollbar-corner {
	        background-color: transparent;
	        display: none;
	    }
	    &::-webkit-scrollbar {
	       	height: 20px;
	        cursor: pointer;
	    }
	    &::-webkit-scrollbar-thumb {
	        background-color: $c-white;
	        border: 1px solid $c-grey3;
	        border-radius: 3px;
	       	height: 20px;
	    }
	    &::-webkit-scrollbar-track {
	    	background-color: transparent;
	    }
	    &::-webkit-scrollbar-track-piece{
	        background-color: #e3e4e6;
	        height: 5px;
	    }
	}

	&__holder {
		max-width: 100%;
		overflow-x: auto;
		margin: 0;
	}

	&__table {
		width: 100%;
		border-collapse: collapse;
	}

	&:before,
	&:after {
		content: '';
		width: 8px;
		position: absolute;
		top: 0;
		bottom: 0;
		pointer-events: none;
		opacity: 0;
		transition: opacity .1s linear;
		z-index: 15;
	}

	&:before {
		left: 0;
		background: -moz-linear-gradient(left,  rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
		background: -webkit-linear-gradient(left,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%);
		background: linear-gradient(to right,  rgba(0,0,0,0.8) 0%,rgba(0,0,0,0) 100%);
	}

	&:after {
		right: 0;
		background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
		background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
		background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
	}

	&--outside-left {
		&:before {
			opacity: .3;
		}
	}

	&--outside-right {
		&:after {
			opacity: .3;
		}
	}

	&__table-wrapper{
	}
}