.item-descr{
	display: flex;
	flex-direction: column;
	width: 100%;
	@include media($lg){
		width: 704px;
	}

	.item__articul{
		margin-bottom: 10px;
	}

	&__title{
		width: 100%;
		margin-bottom: 28px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include media($ms){
			display: block;
		}
		@include media($def){
			margin-bottom: 48px;
		}
	}
	&__title-text{
		color: $c-black;
		font-size: 22px;
		line-height: 28px;
		font-weight: 300;
		margin-bottom: 10px;
		text-align: center;
		font-family: 'Museo Sans';
		@include media($ms){
			margin-bottom: 0;
		}
		@include media($md) {
			text-align: left;
			width: 100%;
		}
		@include media($def){
			font-size: 24px;
			line-height: 36px;
		}
	}
	&__group{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		@include media($defp){
			flex-direction: row;
			align-items: flex-start;
		}
		@include media($md){
			align-items: center;
			justify-content: flex-start;
			flex-direction: column;
		}
	}
	&__title-button{
		color: $c-blue;
		font-size: 10px;
		font-weight: 500;
		font-family: 'Museo Sans';
		text-decoration: underline;
		text-transform: uppercase;
		display: inline-block;
		margin-left: 14px;
		padding: 0 10px;
		cursor: pointer;
		text-align: center;
		transition: color 0.1s ease;
		@include media($ms){
			text-align: left;
		}
		&:hover{
			color: $c-lblue;
		}
	}
	&__info{
		display: flex;
		flex-direction: column;
		padding: 20px;
		background-color: $c-another-grey;
		width: 100%;
	}
	&__info-title{
		margin-bottom: 12px;
		font-size: 18px;
		line-height: 100%;
		font-weight: 300;
		font-family: 'Museo Sans';
		color: $c-black;
	}
	&__info-list{
		margin: 0;
		padding: 0;
		list-style-type: none;
		margin-bottom: 20px;
	}
	&__info-list-item{
		font-size: 16px;
		color: $c-black;
		padding-left: 20px;
		line-height: 24px;
		font-weight: 300;
		font-family: 'Museo Sans';
		position: relative;
		&:after{
			position: absolute;
			left: 8px;
			top: 8px;
			content: "";
			width: 4px;
			height: 4px;
			border-radius: 100%;
			background-color: $c-blue;
		}
	}
}
.item-descr-wrapper{
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-shrink: 0;
	@include media($md){
		width: 400px;
	}
	@include media($def){
		width: 300px;
	}
	@include media($lg){
		width: 340px;
	}
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
   .item-elements{
   	width: 42%;
   }
}
.item-elements{
	display: flex;
	flex-direction: column;
	margin-right: 0;
	width: 100%;
	@include media($md){
		margin-right: 16px;
	}
	@include media($def){
		width: 100%;
		margin-right: 16px;
	}
	@include media($lg){
		margin-right: 26px;
	}

	&__group{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 34px;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		@include media($ms){
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-between;
		}
		@include media($def){
			flex-direction: row;
			align-items: center;
		}
	}
	&__price{
		margin-bottom: 10px;
		font-size: 22px;
		color: $c-black;
		font-weight: 500;
		width: 50%;
		text-align: center;
		font-family: 'Museo Sans';
		@include media($ms){
			text-align: left;
			width: 60%;
			font-size: 24px;
		}
	}
	&__old-price{
		width: 50%;
		color: $c-bgrey;
		font-size: 14px;
		font-weight: 300;
		font-family: 'Museo Sans';
		text-decoration: line-through;
		margin-bottom: 10px;
		text-align: center;
		@include media($ms){
			text-align: left;
			width: 40%;
		}
	}
	&__favorites,
	&__compare{
		margin-bottom: 0;
		@include media($ms){
			margin-bottom: 10px;
		}
		display: flex;
		text-decoration: underline;
		align-items: center;
		color: $c-blue;
		text-transform: uppercase;
		font-family: 'Museo Sans';
		font-weight: 500;
		font-size: 10px;
		letter-spacing: 0.1em;

		svg{
			width: 26px;
			height: 26px;
			fill: $c-blue;
			margin-right: 8px;
		}
		&:hover{
			color: $c-lblue;
			svg{
				fill: $c-lblue;
			}
		}
	}
}