.compare{
	&__buttons{
		margin-bottom: 22px;
		
		display: none;
		@include media($md){
			display: flex;
		}
	}
	&__button{
		display: flex;
		height: 50px;
		align-items: center;
		margin-right: 28px;
		border-bottom: 1px solid transparent;
		color: $c-blue;
		transition: color 0.1s ease, border-color 0.1s ease;
		font-size: 24px;
		font-weight: 300;

		&.is-active{
			color: $c-black;
			border-color: $c-black;
		}
		&:hover{
			color: $c-black;
		}
	}
}
@media (max-width: 767px){
	.compare-block__settings{
		height: 176px!important;
	}
}
.compare-block{
	display: flex;
	width: 100%;
	position: relative;
	padding-top: 186px;
	@include media($md){
		padding-top: 0;
	}

	&__item-bottom{
		display: flex;
		.button{
			margin-right: 12px;
		}
	}

	&__table{
		border-collapse: collapse;
	}
	&__table-wrapper{
		width: 100%;
		min-width: 270px;
		overflow: overlay;
		.table-wrapper__holder{
			padding: 20px 0;
		}
	}
	&__sort{
		display: flex;
		padding-left: 0;
		align-items: center;
		margin: auto 0;

		@include media($def){
			padding-left: 20px;
		}
	}
	&__sort-text{
		padding-right: 20px;
		font-size: 16px;
		font-weight: 300;
	}
	&__title{
		display: flex;
		align-items: center;
		transition: background-color 0.1s ease;
	}
	&__settings{
		width: 100%;
		padding-right: 0;
	    vertical-align: top;
	    position: absolute;
	    display: flex;
	    flex-direction: column;
	    left: 0;
	    top: 0;
	    @include media($md){
			width: 280px;
			padding-right: 20px;
			position: relative;
	    }
	    @include media($def){
	    	padding-right: 48px;
			width: 308px;
	    }
		.button{
			margin-bottom: 20px;
		}
	}
	&__item-image{
		width: 164px;
		height: 164px;
		border: 1px solid $c-lgrey;
		position: relative;
		margin-bottom: 20px;
		@include media($md){
			margin-bottom: 40px;
		}
		img{
			max-width: 164px;
			max-height: 164px;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}
	}
	&__item-name{
		margin-bottom: 14px;
		display: block;
		width: 100%;
		text-align: left;
		font-size: 14px;
		font-weight: 300;
		line-height: 18px;
		color: $c-black;
		text-decoration: none;
	}
	&__item-price{
		margin-bottom: 12px;
		display: block;
		width: 100%;
		text-align: left;
		font-weight: 500;
		font-size: 18px;
	}
	&__left{
		padding: 234px 10px 20px 0;
		width: 110px;
		@include media($md){
			padding: 20px 0;
			width: auto;
		}
	}
	&__th{
		padding-right: 16px;
		padding-bottom: 28px;
		padding-left: 10px;
		@include media($md){
			padding-right: 20px;
		}
	}
	&__tr{
		&:hover{
			.compare-block__td{
				background-color: $c-idk2;
			}
		}
	}
	&__td{
		transition: background-color 0.1s ease;
		background-color: $c-white;
		padding: 12px 0 12px 10px;
	}
	&__button{
		cursor: pointer;
		text-decoration: underline;
		color: $c-blue;
		font-size: 10px;
		text-transform: uppercase;
		font-weight: 500;
		transition: color 0.1s ease;
		letter-spacing: 0.1em;
		text-align: center;
		&:hover{
			color: $c-lblue;
		}
		&:first-of-type{
			margin-bottom: 20px;
			@include media($md){
				margin-bottom: 40px;
			}
		}
	}
}