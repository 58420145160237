.footer-nav{
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 260px;
	display: none;
	@include media($def){
		display: block;
	}
	&__item{
		margin-bottom: 12px;
	}
	&__link{
		color: $c-blue;
		font-weight: 300;
		font-size: 13px;
		line-height: 110%;
		font-family: 'Museo Sans';
		text-decoration: none;
	}
}