.lk-content{
	padding: 20px;
	border: 1px solid $c-grey3;
	display: flex;
	flex-direction: column;
	width: 100%;
	@include media($def){
		width: calc(100% - 270px);
	}

	&__title{
		font-weight: 300;
		font-family: 'Museo Sans';
		color: $c-black;
		font-size: 20px;
		line-height: 110%;
		padding-bottom: 20px;
		border-bottom: 1px solid $c-grey3;
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-direction: column;
		@include media($ms){
			flex-direction: row;
		}
		@include media($md){
			font-size: 24px;
		}
	}
}
.lk{
	display: flex;
	justify-content: space-between;
}
.lk-clear-products{
	color: $c-blue;
	font-size: 10px;
	cursor: pointer;
	font-weight: 500;
	margin-top: 10px;
	text-transform: uppercase;
	text-decoration: underline;
	@include media($ms){
		margin-top: 0;
	}
}