
.js-tabs-block{
    &__button{
        cursor: pointer;
        &.is-active{
            user-select: none;
            cursor: default;
        }
    }
    &__item{
         opacity: 0;
        visibility: hidden;
        position: absolute;
        transition: opacity 0.1s 0s ease, visibility 0s 0.1s ease;
        left: 0;
        top: 0;
        width: 100%;
        &.is-active{
            position: relative;
            visibility: visible;
            opacity: 1;
            transition: opacity 0.1s 0.1s ease, visibility 0s 0s ease;
        }
    }
    &__content{
        position: relative;
    }
}
