.index-news{
    margin-bottom: 20px;
    @include media($def){
        margin-bottom: 44px;
    }
    &__item{
        width: 100%;
        display: flex;
        height: auto;
        margin-bottom: 4px;
        text-decoration: none;
        flex-direction: column;
        max-width: 325px;
        margin-left: auto;
        margin-right: auto;
        @include media($md){
            max-width: 100%;
            height: 296px;
            flex-direction: row;
        }
        &:nth-child(even){
            .index-news{
                &__item-descr{
                    order: 2;
                    @include media($md){
                        order: 1;
                    }
                }
                &__item-image{
                    order: 1;
                    @include media($md){
                        order: 2;
                    }
                }
            }
        }
        &:nth-child(odd){
            .index-news{
                &__item-descr{
                    order: 2;
                }
                &__item-image{
                    order: 1;
                }
            }
        }
        &:hover{
            .index-news__item-text{
                color: $c-dark;
            }
        }
    }
    &__item-descr{
        width: 100%;
        background-color: $c-lgrey;
        display: flex;
        flex-direction: column;
        padding: 20px 26px;
        @include media($md){
            width: 50%;
        }
    }
    &__item-image{
        width: 100%;
        position: relative;
        @include media($md){
            width: 50%;
        }
        img{
            max-width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            max-height: 100%;
        }
    }
    &__item-text{
        color: $c-blue;
        padding-top: 24px;
        margin-bottom: 40px;
        font-size: 15px;
        position: relative;
        line-height: 24px;
        font-weight: 300;
        font-family: 'Museo Sans';
        &:after{
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            content: "";
            background-color: $c-lblue;
            height: 1px;
        }
    }
}