.inner-news{

	&__date{
		justify-content: center;
		display: flex;
		align-items: center;
		margin-bottom: 32px;
		font-size: 12px;
		color: $c-lblue;
		letter-spacing: 0.02em;
		font-weight: 300;
		font-family: 'Museo Sans';
	}
	&__content{
		margin-bottom: 16px;
		@include media($def){
			margin-bottom: 36px;
		}
	}
	&__image{
		margin-bottom: 30px;
	}
}