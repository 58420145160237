.lk-setups{
	padding: 20px 0;

	.form__group{
		display: flex;
		align-items: center;
		flex-direction: column;
		@include media($def){
			flex-direction: row;
		}
	}
	.form__caption{
		width: 100%;
		margin-bottom: 0;
		font-size: 18px;
		color: #000122;
		flex-shrink: 0;
		margin-bottom: 8px;
		@include media($def){
			margin-bottom: 0;
			width: 216px;
		}
		@include media($lg){
			width: 256px;
		}
	}
	.control-holder{
		width: 100%;
		@include media($def){
			width: 344px;
		}
	}
	&__group{
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 30px;
	}
	&__buttons{
		padding-left: 0;
		@include media($lg){
			padding-left: 256px;
			display: flex;
			flex-direction: column;
			max-width: 200px;
		}
		@include media($md){
			flex-direction: row;
		}
	}
	.button{
		margin-right: 10px;
		margin-bottom: 10px;
		@include media($def){
			margin-right: 20px;
			margin-bottom: 5px;
		}
	}
}