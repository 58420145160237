////
/// @group _partials/pagination.scss
////

// scss
// ================================

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	&__btns-group{
		display: flex;
		justify-content: center;
		margin: 0 5.2%;
		align-items: center;
	}
	&__arrow{
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		cursor: pointer;
		svg{
			width: 24px;
			height: 16px;
			fill: $c-blue;
			transition: fill 0.1s ease, transform 0.1s ease;
			transform-origin: center;
		}
		&:hover{
			svg{
				fill: $c-lblue;
			}
		}
		&:active{
			svg{
				fill: $c-dblue;
				transform: scale(0.92);
				transition: fill 0s ease, transform 0s ease;
			}
		}
	}
	&__btn{
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		color: $c-blue;
		text-decoration: none;
	}
}
