////
/// @group _partials/hidden-wrapper.scss
////


// scss
// ================================

.hidden-wrapper {
	position: relative;
	margin: 1.2rem auto;
	background-color: #fff;
	width: 96%;
	max-width: 96%;
}

// размеры
.hidden-wrapper {
	&--mlg {
		max-width: 1380px;
		padding: 16px;
	@include media($md){
		padding: 30px 40px;
	}
	@include media($def){
		padding: 40px 50px;
	}
	}
	&--lg {
		max-width: 1050px;
		padding: 16px;
	@include media($md){
		padding: 30px 40px;
	}
	@include media($def){
		padding: 40px 50px;
	}
	}

	&--glry{
	    max-width: 1120px;
	    max-height: calc(100vh - 30px);
	    padding: 10px;
	    height: 100vh;
	    width: 100%;
	    border-radius: 10px;
	}

	&--md {
		max-width: 768px;
	padding: 16px;
	@include media($md){
		padding: 30px 40px;
	}
	@include media($def){
		padding: 40px 50px;
	}
	}

	&--sm {
		max-width: 550px;
	padding: 16px;
	@include media($md){
		padding: 30px 40px;
	}
	@include media($def){
		padding: 40px 50px;
	}
	}
}

// отображение
.hidden-wrapper {
	&--strip {
		background: none;
		padding: 0;
	}

	&--no-gap {
		padding: 0;
	}
}

// показать элементы только внутри
.hidden-wrapper {
	._show-in--hidden-wrapper {
		display: block;
	}
}
