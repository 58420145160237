////
/// @group _partials/form.scss
////


// scss
// ================================

.form {
	position: relative;
	font-size: 1rem;

	&__group {
		display: block;
		position: relative;
		margin: 0 0 22px;
		& & {
			margin: 0 0 18px;
		}
	}

	&__label {
		cursor: default;
		display: inline-block;
		vertical-align: top;
		user-select: none;
		margin-bottom: .4rem;
	}

	&__caption {
		font-size: 14px;
		font-weight: 300;
		font-family: 'Museo Sans';
		margin-bottom: 10px;
		color: $c-blue;

		&.is-focused{
			color: $c-black;
		}
	}

	&__info {
		display: none;
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 0;
		font-size: .7em;
		background-color: #000;
		color: #fff;
		padding: .1em .3em;
		input:focus ~ &,
		textarea:focus ~ &,
		select:focus ~ & {
			display: block;
		}
	}
}

label.form__label {
	cursor: pointer;
}


.control-holder {
	position: relative;

	label.has-error {
		display: inline-block;
		vertical-align: top;
		user-select: none;
		font-size: 10px;
		line-height: 100%;
		padding: .1em .5em;
		background-color: transparent;
		color: $c-tomato-light;
		position: absolute;
		top: 100%;
		right: 0;
		font-family: 'Museo Sans';
		font-weight: 700;
		border-radius: 2px;
	}

	&--text {
		input, textarea, select {
			display: block;
			width: 100%;
			line-height: 18px;
			color: $c-blue;
			font-size: 16px;
			font-weight: 100;
			font-family: 'Museo Sans';
			outline: none;
			border: 1px solid $c-grey;
			border-radius: 4px;
			transition: border-color 0.1s ease;
			@include media($sm){
				font-size: 14.5px;
				line-height: 20px;
			}
			&.has-error{
				border-color: $c-tomato-light;
				&::-webkit-input-placeholder {
					color: $c-tomato-light;
				}
				&::-moz-placeholder{
					color: $c-tomato-light;
				}
				&:-moz-placeholder{
					color: $c-tomato-light;
				}
				&:-ms-input-placeholder{
					color: $c-tomato-light;
				}
			}
			&:focus{
				border-color: $c-blue;
				&::-webkit-input-placeholder {
					color: $c-black;
				}
				&::-moz-placeholder{
					color: $c-black;
				}
				&:-moz-placeholder{
					color: $c-black;
				}
				&:-ms-input-placeholder{
					color: $c-black;
				}
			}
		}
		input, select {
			height: 44px;
			padding: 0 14px;
		}
		textarea {
			padding: 14px;
			resize: none;
			min-height: 7em;
			max-height: 18em;
		}

	}

	&--flag {
		label.has-error{
			left: 0;
			right: auto;
			white-space: nowrap;
		}
		label {
			user-select: none;
			margin-right: 1em;
			cursor: pointer;
			position: relative;
			z-index: 1;
			cursor: pointer;
		}
		ins {
			position: relative;
			display: inline-block;
			vertical-align: text-bottom;
			width: 28px;
			height: 28px;
			background-color: #b9c0cd;
			border: 1px solid $c-lblue;
			margin-right: 10px;
			flex-shrink: 0;
			box-shadow: inset 0 0 0 4px #fff;
		}
		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
			z-index: -1;
			&[type="radio"] {
				~ ins {
					border-radius: 50%;
				}
			}
			&:checked {
				~ ins {
					background-color: $c-lblue;
				}
				~ span{
					color: $c-lblue;
				}
			}
			&:disabled {
				~ ins,
				~ span {
					opacity: .5;
					cursor: not-allowed;
				}
			}
		}
	}

	&--file {
		label {
			position: relative;
			display: inline-block;
			vertical-align: top;
		}

		input {
			position: absolute;
			outline: none;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
		}

		._ellipsis {
			max-width: 200px;
			width: auto;
			display: inline-block;
			vertical-align: top;
		}
	}
}


.download-photo{
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	cursor: pointer;
	width: 210px;

	&__input{
		display: none;
	}
	&__ins{
		width: 16px;
		height: 16px;
		margin-right: 18px;
		position: relative;
		&:after,
		&:before{
			width: 16px;
			height: 1px;
			background-color: $c-blue;
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			transition: background-color 0.1s ease;
		}
		&:after{
			transform: rotate(90deg);
		}
	}
	&__text{
		text-transform: uppercase;
		letter-spacing: 0.1em;
		font-weight: 500;
		font-size: 10px;
		text-decoration: underline;
		transition: color 0.1s ease;
	}
	&:hover{
		.download-photo{
			&__ins{
				&:after,
				&:before{
					background-color: $c-lblue;
				}
			}
			&__text{
				color: $c-lblue;
			}
		}
	}
}