.page-video__item--gallery {
	@include media($sm) {
		margin-right: 32px;
		width: calc(50% - 16px);

		&:nth-child(2n) {
			margin-right: 0;
		}
	}

	@include media($def) {
		width: calc(33.3% - 21px);

		&:nth-child(2n) {
			margin-right: 32px;
		}

		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	.page-video__item-img {
		object-fit: contain;
	}
}
