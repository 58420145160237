.main-slider-wrapper{
	width: 100%;
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid $c-grey3;
	margin-bottom: 30px;
	@include media($def){
		margin-bottom: 50px;
	}
}
.main-slider-dummy{
	width: 28.9%;
	position: relative;
	min-width: 310px;
	flex-shrink: 0;
	display: none;
	@include media($def){
		display: block;
	}
}
.main-slider{
	width: 100%;
	margin-left: auto;
	padding-top: 20px;
	padding-bottom: 28px;
	@include media($def){
		width: 69.4%;
	}
	&__content{
		position: relative;
		height: 200px;
		width: 100%;
		margin-bottom: 0;
		@include media($sm){
			height: 400px;
		}
	}
	&__item{
		height: 200px;
		//background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
		position: relative;
		background-size: 100% 100%;
		@include media($sm){
			height: 300px;
		}
		@include media($ms){
			height: 400px;
		}
		@include media($md){
			height: 500px;
		}
		@include media($def){
			height: 400px;
		}
	}
	&__item-text{
		color: $c-white;
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 26px;
		max-width:  500px;
		height: 106px;
		overflow: hidden;
		font-weight: 500;
		font-family: 'Museo Sans';
		@include media($md){
			font-size: 26px;
			line-height: 30px;
			height: 112px;
		}
		@include media($def){
			font-size: 30px;
			line-height: 36px;
			height: 112px;
		}
	}
	&__item-content{
		position: absolute;
		width: 100%;
		max-width: 740px;
		padding: 16px;
		bottom: auto;
		top: 0;
		left: 0;
		z-index: 15;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@include media($md){
			bottom: 0;
			top: auto;
			padding: 36px;
		}
		@include media($def){
			padding: 46px;
		}
	}
	&__arrow {
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		position: absolute;
		bottom: 20px;
		background-color: $c-white;
		border-bottom: 1px solid $c-lblue;
		border-top: 1px solid $c-lblue;
		width: 50px;
		height: 50px;
		cursor: pointer;
		z-index: 15;
		transition: background-color 0.1s ease;
		user-select: none;
		svg{
			fill: $c-lblue;
			width: 24px;
			height: 16px;
		}
		&--left{
			border-radius: 4px 0 0 4px;
			border-left: 1px solid $c-lblue;
			right: 70px;
		}
		&--right{
			border-radius: 0 4px 4px 0;
			border-right: 1px solid $c-lblue;
			right: 20px;
		}
		&:hover{
			background-color: $c-lblue;
			svg{
				fill: $c-white;
			}
		}
		&:active{
			background-color: $c-blue;
			transition: background-color 0s ease;
		}
	}

	&__info {
		margin: 35px 0;
		font-size: 15px;
		@include media($sm) {
			font-size: 13px;
		}
	}
}