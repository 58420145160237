.bottom-slider{
	&__item{
		margin: 0 4px;
		width: 94px;
	}
}

.bottom-slider-wrapper{
	position: relative;
		padding: 0;
	@include media($ms){
		padding: 0 50px;
	}
	@include media($lg){
		padding: 0;
	}
	.main-slider__arrow--left{
		left: 0;
		right: auto;
		border-right: 1px solid $c-lblue;
		top: 0;
		bottom: 0;
		margin: auto 0;
		display: none;
		@include media($lg){
			left: -60px;
		}
		@include media($ms){
			display: flex;
		}
	}
	.main-slider__arrow--right{
		right: 0;
		left: auto;
		border-left: 1px solid $c-lblue;
		top: 0;
		bottom: 0;
		margin: auto 0;
		display: none;
		@include media($lg){
			right: -60px;
		}
		@include media($ms){
			display: flex;
		}
	}
}