.lng{
	width: auto;
	height: 34px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	&__active-text{
		transition: color 0.1s ease;
		color: $c-black;
		text-transform: uppercase;
		font-size: 10px;
		letter-spacing: 0.1em;
		font-weight: 300;
		font-family: 'Museo Sans';
		margin: 0 5px;
	}
	&__item{
		text-decoration: none;
		color: $c-black;
		font-size: 10px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		font-weight: 300;
		font-family: 'Museo Sans';
		display: inline-block;
		padding-bottom: 0;
		padding-left: 0;
		width: 100%;
		margin: 0 5px;
	}
	&__active-image{
		transition: fill 0.1s ease;
		width: 12px;
		text-transform: uppercase;
		height: 6px;
		fill: $c-blue;
		margin-left: 4px;
		transition: fill 0.1s ease;
	}
	&__items{
		position: absolute;
		top: 100%;
		left: -1px;
		width: 36px;
		background-color: transparent;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		user-select: none;
		transform: translateY(-60px);
		border-radius: 0 0 8px 8px;
		z-index: -1;
		visibility: hidden;
		opacity: 0;
		transition: transform 0.1s 0s ease, opacity 0.2s 0s ease, visibility 0s 0.1s ease, background-color 0.1s ease;
		&.is-active{
			opacity: 1;
			z-index: 15;
			visibility: visible;
			transform: translateY(0);
			transition: transform 0.1s 0.1s ease, opacity 0.2s 0.2s ease, visibility 0s 0s ease, background-color 0.1s ease;
		}
	}
	&__active{
		cursor: pointer;
		height: 100%;
		align-items: center;
		user-select: none;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		z-index: 16;
		background-color: transparent;
		padding-top: 4px;
		padding-bottom: 2px;
		&:hover{
			.lng__active-text{
				color: $c-lblue;
			}
			.lng__active-image{
				fill: $c-lblue;
			}
		}
	}
}