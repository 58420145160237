.phones-block{
	position: relative;
	align-items: center;
	display: none;
	justify-content: center;
	width: 48%;
	max-width: 220px;
	padding-left: 20px;
    border: 1px solid #d5d9e1;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    height: 44px;

	@include media($def){
		display: flex;
	}
	&__item{
		font-size: 19px;
		font-weight: 500;
		font-family: 'Museo Sans';
		text-decoration: none;
		color: $c-blue;
		white-space: nowrap;
		&--main{
		}
	}
	&__arrow{
		width: 20px;
		height: 20px;
		margin: 0 5px;
		cursor: pointer;
		display: block;
		position: relative;
		svg{
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			fill: $c-blue;
		    width: 18px;
    		height: 16px;
			transition: fill 0.1s ease;
		}
		&:hover{
			svg{
				fill: $c-lblue;
			}
		}
	}
	&__more{
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0 10px;
		background-color: $c-white;
		text-align: center;
		width: 220px;
	    left: -1px;
		top: 100%;
		min-width: 100%;
		max-height: 0px;
		transition: padding 0.3s ease, max-height 0.3s ease;
		overflow: hidden;
		z-index: 15;
		border: 1px solid #d5d9e1;
   		box-shadow: 1px 6px 20px -3px rgba(89, 88, 95, 0.1);
   		border-top: none;
		&.is-active{
			padding: 2px 16px 16px;
			max-height: 1000px;
			transition: padding 0.4s ease, max-height 2.2s ease;
		}
	}
}

.phones-block__more{
	.phones-block__item{
		&:nth-child(1){
			transform: translateY(-4px);
		}
	}
}