.items-slider{
	margin-bottom: 68px;
    @include media($defp){
        margin-bottom: 28px;
    }
    &__item{
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        outline: none;
        .item{
        	margin: 0 auto;
        }
    }
    .main-slider__arrow{
        bottom: -50px;
        margin: 0 auto;
        left: 0;
        right: 0;
        &.slick-disabled{
            pointer-events: none;
            border-color: $c-grey;
            svg{
                fill: $c-grey;
            }
        }
        @include media($defp){
            bottom: calc(100% + 26px);
            margin: 0;
        }
        &--left{
             right: 50px;
            // left: 0;
            // @include media($def){
                left: auto;
            // }
        }
        &--right{
            left: 50px;
            right: 0;
            // @include media($def){
                // right: 0;
                left: auto;
            // }
        }
    }
}

