.order{
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.order-content{
	display: flex;
	flex-direction: column;
	width: 100%;
	border: 1px solid $c-grey3;
	padding: 26px 20px;
	@include media($defp){
		width: calc(100% - 490px);
	}

	&__map{
		position: relative;
		width: 100%;
		height: 248px;
		border: 1px solid $c-grey3;
		margin-left: 0;
		@include media($md){
			height: 358px;
		}
	}
	.ui-selectmenu-button{
		width: 100%;
		max-width: 250px;
		@include media($lg) {
			max-width: 508px;
			width: calc(100% - 130px);
		}
	}
	&__input-group{
		display: flex;
		margin-bottom: 20px;
		align-items: flex-start;
		flex-direction: column;
		@include media($lg){
			flex-direction: row;
			align-items: center;
		}
	}
	&__text{
		flex-shrink: 0;
		font-weight: 300;
		font-size: 14px;
		color: $c-ddblue;
		width: 100%;
		padding-left: 0;
		@include media($lg){
			width: 240px;
			padding-left: 50px;
		}
	}
	&__accordeon{
	}
	&__title{
		font-size: 24px;
		font-weight: 300;
		color: $c-black;
		margin-bottom: 18px;
		padding-bottom: 20px;
		border-bottom: 1px solid $c-grey3;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		@include media($md){
			flex-direction: row;
		}
	}
	&__edit-data{
		color: $c-blue;
		font-weight: 500;
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		margin-top: 20px;
		@include media($md){
			margin-top: 0;
		}
	}
	&__accordeon-content{
		padding-bottom: 40px;
	}
	&__accordeon-button{
		width: 100%;
		display: flex;
		min-height: 70px;
		align-items: center;
		background-color: $c-lgrey;
		padding: 10px 16px;
		transition: background-color 0.1s ease;
		span{
			color: $c-blue;
			font-weight: 300;
			font-size: 20px;
			font-weight: 24px;
		}
		&.is-active{
			background-color: #ebebf1;
			cursor: default;
		}
	}
	.form__group{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@include media($lg){
		align-items: center;
			flex-direction: row;
		}
	}
	&__button{
		margin-left: 0;
	}
	.form__caption{
		width: 100%;
		padding-left: 0;
		flex-shrink: 0;
		@include media($lg){
			width: 240px;
			padding-left: 50px;
		}
	}
	&__feed-btn{
		cursor: pointer;
		text-transform: uppercase;
		text-decoration: underline;
		font-size: 10px;
		font-weight: 500;
		transition: color 0.1s ease;
		margin: 30px 38px 30px 0;
		&:hover{
			color: $c-lblue;
		}
	}
	&__bucklets{
		font-size: 14px;
		margin-left: 0;
		ins{
			width: 22px;
			height: 22px;
			background-color: $c-white;
			border: 1px solid $c-lblue;
			border-radius: 3px;
			box-shadow: none;
			position: relative;
			&:after{
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				width: 16px;
				height: 12px;
				background-size: cover;
				background-position: 50%;
				background-repeat: no-repeat;
				background-image: url(../pic/okay_check.svg);
				opacity: 0;
				content: "";
				transition: opacity 0.1s ease;
			}
		}
		span{
			transition: color 0.1s ease;
		}
		label{
			// &:hover{
			// 	span{
			// 		color: $c-lblue;
			// 	}
			// }
		}
		input {
			&:checked {
				~ ins {
					background-color: $c-white;
					&:after{
						opacity: 1;
					}
				}
				span{
					text-decoration: underline;
				}
			}
		}
	}
	&__step{
		&--first{
			margin-bottom: 20px;
		}
		&--third{
			& > .form__group{
				margin-bottom: 16px;
			}
			.order-content__accordeon-button{
				margin-bottom: 0;
			}
		}
	}
	.control-holder{
		width: 100%;
	}
	&__sub-text{
		font-size: 16px;
		color: #0d1017;
		font-weight: 300;
		margin-left: 0;
		@include media($lg){
			margin-left: 240px;
		}
	}
}

.ui-selectmenu-menu .ui-menu{
	max-height: 50vh;
}