.search-results{
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	max-width: 100%;
	background-color: $c-white;
	border-radius: 2px;
	border: 1px solid $c-grey;
	display: none;
	padding: 14px;
	//max-height: calc(100vh - 150px);
	max-height: 100vh;
	overflow: auto;

	@include media($defp){
		max-width: 330px;
	}

	&.is-open{
		display: block;
	}

	&__list{
		list-style-type: none;
		padding: 0;
		margin: 0;
		margin-bottom: 14px;
		border-top: 1px solid $c-grey3;
	    padding-top: 10px;
	    padding-bottom: 10px;
    	margin-top: 10px;
		border-bottom: 1px solid $c-grey3;
	}
	&__title{
		padding: 4px 0;
		display: block;
		font-weight: 100;
		font-size: 16px;
		color: #000;
		transition: color 0.1s ease;
		text-decoration: none;
		font-family: 'Museo Sans';

		&:hover{
			color: $c-lblue;
		}
	}
	&__allresult{
		color: $c-black;
		font-size: 14px;
		display: block;
		font-weight: 300;
		text-decoration: none;

		&:hover{
			color: $c-lblue;
		}
	}
}

.result-item{
	text-decoration: none;
	display: flex;
	padding: 9px 4px 9px 0;
	&__descr-bottom{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	&__descr{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: calc(100% - 80px);
		padding-left: 4px;
	}
	&__image{
		border: 1px solid $c-lgrey;
		max-width: 75px;
		max-height: 75px;
		margin: auto;
		transition: border-color 0.1s ease;
	}
	&__price{
		font-size: 18px;
		font-weight: 500;
		color: $c-black;
		@include media ($def) {
			font-size: 15px;
		}
	}
	&__title{
		font-size: 14px;
		font-weight: 300;
		max-height: 56px;
		color: $c-blue;
		overflow: hidden;
		transition: color 0.1s ease;
	}
	&:hover{
		.result-item{
			&__image{
				border-color: $c-black;
			}
			&__title{
				color: $c-lblue;
			}
		}
	}
}