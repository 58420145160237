.catalogues{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 40px;
	@include media($def){
		justify-content: flex-start;
	}
}

.catalogues-item{
	width: 200px;
	margin: 0 20px 20px 20px;
	display: flex;
	flex-direction: column;
	text-decoration: none;
	@include media($md){
		margin: 0 8px 20px 8px;
	}
	@include media($def){
		margin: 0 54px 20px 20px;
		width: 220px;
	}

	&__image{
		position: relative;
		width: 134px;
		height: 192px;
		margin-bottom: 18px;
		img{
			position: absolute;
			left :0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
		}
	}
	&__title{
		padding-top: 22px;
		position: relative;
		font-size: 16px;
		line-height: 20px;
		color: $c-blue;
		font-weight: 300;
		font-family: 'Museo Sans';
		transition: color 0.1s ease;
		margin-bottom: 12px;
		overflow: hidden;
		height: 82px;
		&:after{
			position: absolute;
			left: 0;
			top: 0;
			width: 20px;
			height: 1px;
			background-color: $c-lblue;
			content: "";
			display: none;
		}
	}
	&__descr{
		color: $c-blue;
		font-family: 'Museo Sans';
		font-weight: 100;
		font-size: 14px;
	}
	&:hover{
		.catalogues-item{
			&__title{
				text-decoration: underline;
				color: $c-lblue;
			}
		}
		.catalog-item__overlay{
			opacity: 0;
		}
	}
}