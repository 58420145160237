.filter{
	// width: 40%;
	// min-width: 240px;
	width: 260px;
	position: fixed;
	flex-shrink: 0;
    background-color: $c-white;
    z-index: 25;
    transform: translateX(-400%) scale(0.9);
    opacity: 0.5;
    top: 70px;
    left: 0;
    padding: 10px;
    transition: transform 0.1s ease, z-index 0s 0.1s, opacity 0.1s;
    height: calc(100% - 70px);
    overflow: overlay;
	padding-bottom: 30px;
	min-height: calc(100% - 70px);



	@include media($def) {
		top: 0;
		position: relative;
		transform: translate(0);
    	height: auto;
		opacity: 1;
		padding: 0;
	}
	&__items-list{
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
	&.is-active{
		transform: translateY(0);
		z-index: 25;
		opacity: 1;
		transition: transform 0.1s ease, z-index 0s 0s, opacity 0.1s;
	}

	&__reset-button{
		display: flex;
		align-items: center;
		color: $c-blue;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 500;
		font-family: 'Museo Sans';
		text-decoration: none;
		svg{
			width: 22px;
			height: 22px;
			fill: $c-blue;
			margin-right: 10px;
		}
		span{
			text-decoration: underline;
		}
		&:hover{
			svg{
				fill: $c-lblue;
			}
		}
		&.js-loading{
			svg{
				animation: spin 5s infinite;
			}
		}
	}

	&__item-link{
		text-decoration: none;
		display: flex;
		align-items: center;
	}
	&__item-input{
		display: none;
	}
	&__item-text{
		color: $c-blue;
		transition: color 0.1s ease;
		text-decoration: none;


		font-size: 14px;
		font-family: 'Museo Sans';
		font-weight: 300;
		line-height: 110%;
	}
	&__item-ins{
		width: 20px;
		height: 20px;
		border-radius: 3px;
		border: 1px solid $c-grey;
		margin-right: 10px;
		display: block;
		transition: border-color 0.1s ease;
	}
	&__group{
		margin-bottom: 32px;
	}
	&__title{
		margin-bottom: 20px;
		color: $c-black;
		padding-bottom: 10px;
		border-bottom: 1px solid $c-omgray;
		display: block;


		font-weight: 300;
		font-family: 'Museo Sans';
		font-size: 16px;
		line-height: 110%;
	}
	&__item{
		margin-bottom: 10px;
		&.is-active{
			.filter__item-text{
				color: $c-lblue;
				text-decoration: underline;
			}
			.filter__item-ins{
				background: url(../pic/okay_check.svg);
		    	background-repeat: no-repeat;
		    	background-size: 90%;
		    	background-position: 50%;
		    	border-color: $c-lblue;
			}
		    
		}
		&:hover{
			a{
				.filter__item-text{
					color: $c-lblue;
				}
				.filter__item-ins{
					border-color: $c-lblue;
				}
			}
		}
	}
}

@keyframes spin {
  0%   { transform: rotate(0); }
  100% { transform: rotate(720deg); }
}