.items-catalog{
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	padding-bottom: 10px;
	border-bottom: 1px solid $c-grey3;
	&__items-group{
		width:  100%;
		@include media($def){
			width:  calc(100% - 270px);
		}
		&--w100{
			width: 100%!important;
		}
	}
	&__show-more-button-wrapper{
		display: flex;
		justify-content: center;
		margin-bottom: 48px;
	}
	&__text{
		font-size: 16px;
		color: $c-black;
		font-weight: 300;
		font-family: 'Museo Sans';
	}
	&__filters{
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		align-items: center;
		margin-bottom: 20px;
		@include media($def){
			margin-bottom: 30px;
			padding: 0 24px;
		}
	}
	&__filters-group{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	&__filter-item{
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		color: $c-blue;
		font-size: 16px;
		color: $c-black;
		font-weight: 300;
		transition: color 0.1s ease;
		text-decoration: none;
		font-family: 'Museo Sans';
		&:hover{
			color: $c-lblue;
			svg{
				fill: $c-lblue;
			}
		}
		svg{
			fill: $c-blue;
			width: 12px;
			height: 12px;
			margin-left: 12px;
		}
	}
}
.items-catalog-grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 0 10px 0;
	@include media($def){
		margin: 0 -6px 10px -6px;
		justify-content: flex-start;
	}
	&__item{
		margin: 6px;
		position: relative;
		width: auto;
		display: flex;
		&.item--list{
			width: auto;
			@include media($md){
				width: 100%;
			}
		}
		&:after{
			background-color: $c-grey3;
			position: absolute;
			content: "";
			right: 0;
			top: 0;
			width: 1px;
			height: 100%;
		}
		&:before{
			background-color: $c-grey3;
			position: absolute;
			content: "";
			right: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
		}
	}
}