.contacts-grid{
	display: flex;
	flex-direction: column;
}
.contacts-block{
	display: flex;
	flex-direction: column;
	border: 1px solid $c-grey3;
	min-height: auto;
	margin-bottom: 36px;
	position: relative;
	@include media($defp){
		flex-direction: row;
	}
	&__info{
		width: 100%;
		padding: 10px;
		@include media($md){
			padding: 20px;
		}
		@include media($lg){
			width: 520px;
		}
		@include media($defp){
			width: 420px;
		}
	}
	&__title{
		font-size: 19px;
		color: $c-black;
		font-weight: 300;
		line-height: 26px;
		font-family: 'Museo Sans';
		min-height: 46px;
		border-bottom: 1px solid $c-grey3;
		margin-bottom: 18px;
	}
	&__addres{
		margin-bottom: 4px;
		color: $c-blue;
		font-size: 15px;
		font-weight: 300;
		font-family: 'Museo Sans';
	}
	&__email{
		color: $c-blue;
		font-size: 15px;
		font-weight: 300;
		font-family: 'Museo Sans';
		text-decoration: underline;
		&:hover{
			color: $c-lblue;
		}
	}
	&__emails{
		margin-bottom: 24px;
	}
	&__work-data{
		color: $c-blue;
		font-size: 14px;
		font-weight: 300;
		font-family: 'Museo Sans';
		span{
			display: block;
			margin-bottom: 8px;
		}
	}
	&__phone{
		text-decoration: none;
		font-weight: 500;
		font-family: 'Museo Sans';
		font-size: 19px;
		line-height: 100%;
		color: $c-blue;
		margin-bottom: 4px;

	}
	&__phones{
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;
		align-items: flex-start;
		justify-content: flex-start;
	}
	&__map{
		border-left: 1px solid $c-grey3;
		position: relative;
		width:100%;
		height: 340px;
		@include media($lg){
			width: calc(100% - 520px);
		}
		@include media($defp){
			height: auto;
			width: calc(100% - 420px);
		}
	}
}