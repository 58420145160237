.catalog-grid{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	border-bottom: 1px solid #d4d8e6;
}
.catalog-block{
	margin-bottom: 30px;
	@include media($def){
		margin-bottom: 50px;
	}
}
.catalog-item{
	width: 100%;
	display: block;
	text-decoration: none;
	min-width: 233px;
	max-width: 290px;
	margin: 0 6px 20px;
	@include media($ms){
		width: 48%;
	}
	@include media($md){
		width: 31%;
	}
	@include media($def){
		width: 23%;
	}
	&__image{
		position: relative;
		width: 100%;
		height: 174px;
		img{
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}
	}
	&__bg{
		background-color: $c-lgrey;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	&__content{
		padding: 20px;
	}
	// &__overlay{
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: $c-grey4;
	// 	opacity: 0;
	// 	transition: opacity 0.5s ease;
	// 	&:before,
	// 	&:after{
	// 		position: absolute;
	// 		left: 0;
	// 		top: 0;
	// 		bottom: 0;
	// 		right: 0;
	// 		margin: auto;
	// 		content: "";
	// 		width: 20px;
	// 		height: 1px;
	// 		background-color: $c-lblue;
	// 		z-index: 5;
	// 	}
	// 	&:after{
	// 		transform: rotate(95deg);
	// 	}
	// }
	&__title{
		padding-top: 20px;
		position: relative;
		color: $c-dark;
		font-size: 15px;
		line-height: 20px;
		font-weight: 300;
		font-family: 'Museo Sans';
		&:after{
			position: absolute;
			left: 0;
			top: 0;
			width: 20px;
			height: 1px;
			background-color: $c-lblue;
			content: "";
			display: none;
		}
	}
	&:hover{
		.catalog-item{
			&__title{
				color: $c-lblue;
			}
			&__overlay{
				// opacity: 0.6;
				opacity: 0;
			}
		}
	}
}