////
/// @group _partials/fPopUp.scss
////


// scss
// ================================

#fPopUp {
    max-width: 80% !important;
    min-width: 280px !important;

    @include media( $md ) {
    	max-width: 50% !important;
    	min-width: 400px !important;
    }
}