.back-next{
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
	@include media($ms){
		flex-direction: row;
	}

	&__block{
		&--back{
			svg{
				margin-right: 28px;
			}
			.back-next__link{
				padding-right: 20px;
			}
		}
		&--next{
			svg{
				margin-left: 28px;
			}
			.back-next__link{
				padding-left: 20px;
			}
		}
	}
	&__link{
		padding: 8px 0;
		display: flex;	
		align-items: center;
		color: $c-blue;
		font-weight: 700;
		font-size: 14px;
		line-height: 100%;
		letter-spacing: 0.1em;
		font-family: 'Museo Sans';
		text-decoration: none;
		text-align: center;

		svg{
			width: 24px;
			height: 16px;
		}
		&:hover{
			svg{
				fill: $c-lblue;
			}
		}
	}
}