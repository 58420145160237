.order-cart{
	width: 446px;
	padding: 20px 40px;
	display: none;
	border: 1px solid $c-grey3;
	@include media($defp){
		display: block;
	}

	&__end-block{
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		border-top: 1px solid $c-grey3;
		flex-direction: column;
		padding: 40px 0 30px 0;
	}
	&__end-sum{
		margin-bottom: 28px;
		font-size: 24px;
		font-weight: 500;
		color: $c-black;
		line-height: 110%;
	}
	&__item{
		display: flex;
		width: 100%;
		margin-bottom: 36px;


	}
	&__item-name{
		font-size: 14px;
		color: $c-blue;
		line-height: 18px;
		text-decoration: none;
		font-weight: 300;
		&:hover{
			color: $c-lblue;
		}
	}
	&__item-image{
		flex-shrink: 0;
		border: 1px solid #f5f5f8;
		width: 75px;
		height: 75px;
	}
	&__item-descr{
		margin-left: 20px;
	}
	&__item-price{
		font-weight: 500;
		color: $c-black;
	}
	&__edit-cart{
		color: $c-blue;
		text-decoration: underline;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		cursor: pointer;
		font-size: 10px;
		&:hover{
			color: $c-lblue;
		}
	}
	&__sum{
		font-size: 14px;
		font-weight: 300;
		color: $c-blue;
		margin-bottom: 28px;
	}
	&__title{
		font-size: 24px;
		font-weight: 300;
		color: $c-black;
		padding-bottom: 14px;
		margin-bottom: 20px;
		border-bottom: 1px solid $c-grey3;
	}
}




// Минималльный браейкпоит
	// все текста до этого предела - малы
	// корзина выходит за рамки
	$cart-mq-min: 480px !default;

	// Промежуточный брейкпоинтов
	// используется для корректировки размерностей
	$cart-mq-middle: 640px !default;

	// Брейпоинт на котором происходит
	// схлопывание / открытие
	// шапки товара и его основного контента
	$cart-mq-collapse: 924px !default;


	// Вертикальные и горизонтальные
	// отступы, как наружные, так и внутрение
	// как правило, меняются в промежуточном брейкпоите
	$cart-ver-min: 1rem !default;
	$cart-hor-min: 1.5rem !default;
	$cart-ver-max: 1.5rem !default;
	$cart-hor-max: 2.5rem !default;

	// Основные цвета которые использубтся,
	// большинство взяты с конфига, сами значения
	$cart-color-black: #000 !default;       // $color-black
	$cart-color-gray75: #404040 !default;   // $color-gray75
	$cart-color-gray50: #807f7f !default;   // $color-gray50
	$cart-color-gray25: #bfbfbf !default;   // $color-gray25
	$cart-color-gray10: #e6e6e6 !default;   // $color-gray10



// scss
// ================================

	// основной котейнер корзины
	.cart {
		position: relative;
		font-size: 1.1rem;
		padding: $cart-ver-min $cart-hor-min;
		border: 1px solid $c-grey;

		@include media( $cart-mq-middle ) {
			padding: $cart-ver-max $cart-hor-max;
		}

		// внутри view-size - вылазим за пределы,
		// чтобы уйти от двойных паддингов
		.view-size & {
			@include media( $cart-mq-min, max ) {
				margin-left: -$cart-hor-min;
				margin-right: -$cart-hor-min;
			}
		}
	}


	// модульный блок, который используйтся, в основном,
	// для обертки и акцентирования цветом
	.cart-div {
		position: relative;
		&--emphasis {
			background-color: transparent;
			padding: .75em 1.25em;

			.cart-footer & {
				@include media( $cart-mq-middle ) {
					padding: $cart-ver-max $cart-hor-max;
				}
			}

			.cart-item & {
				display: inline-block;
				@include media( $cart-mq-min, max ) {
					padding: .5em;
				}
			}
		}
	}


	// шапка корзины
	.cart-header {
		position: relative;
		margin-bottom: 10px;

		// в поп-апе - делаем зазор
		// для кнопки закрыть от magnific'a
		.hidden-wrapper & {
			padding-right: 2rem;
		}
	}


	// основное наполнение  корщины
	.cart-section {
		position: relative;
	}


	// список товаров
	.cart-list {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}


// элемент товара
// блок-элемент списка .cart-list
.cart-item {
	position: relative;
	list-style-type: none;
	padding: $cart-ver-min $cart-hor-min;
	margin: 0 ( -$cart-hor-min );

	&--last{
		background-color: beige;
	}

	@include media( $cart-mq-middle ) {
		padding: $cart-ver-max $cart-hor-max;
		margin: 0 ( -$cart-hor-max );
	}

	// шапка товара,
	// используйется при схлопнутом товаре
	&__head {
		&:after {
			content: '';
			display: block;
			height: 0;
			clear: both;
		}
		@include media( $cart-mq-collapse ) {
			display: none;
		}
	}

		// изображение товара, в шапке
		&__preview {
			width: 52px;
			float: left;
			margin-right: 1em;

			@include media( $cart-mq-min ) {
				width: 70px;
			}

			@include media( $cart-mq-middle ) {
				width: 100px;
			}
		}

		// "Каректка" для открытия товара
		&__opener {
			float: right;
			margin-left: 1em;
		}

		// Самая основная инфа по товару
		// - название
		// - количестов и общая сумма
		&__short-info {
			overflow: hidden;

			> * {
				max-width: 100%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				.cart--info & {
					white-space: normal;
					overflow: visible;
				}
			}
		}

	// тело товара
	// используйется как контейнер для схлопываний
	// чтобы иметь возможность формировать люьую разметку внутри
	&__body {
			@include media( $cart-mq-collapse, max ) {
				display: none;
			}
		.cart--compact & {
			display: none;
		}
	}

	// основной контейнер с элементами товара
	// в случае дополнений к товару, аля подарок или дополнительный сервис и тд
	// рекомендуется использовать отдельный контейнер рядом
	&__main {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid $c-grey;

		// если не схлопнутый - все элементы
		// долждны помещаться в один ряд - аля таблица
		@include media( $cart-mq-collapse ) {
			flex-wrap: nowrap;
		}
	}

	// блок из &__main ▲
	&__cell {
		padding-left: 1em;
		@include media( $cart-mq-collapse ) {
			padding-left: 2em;
		}

		// удаление, стоит жестко
		// ширина мала и не влияет на общую картину
		&--remover {
			flex-shrink: 0;
			width: 40%;
			padding-bottom: 1em;
			border-bottom: 1px solid $cart-color-gray10;
			margin-bottom: 1em;
			padding-left: 0;

			@include media( $cart-mq-collapse ) {
				width: 2em;
				width: auto;
				margin: 0;
				padding: 0;
				border-width: 0;
			}
		}

		// основное изображение товара
		// может изменится 1 - 2 раза
		// при схлопывании - вообще отсутствует (display: none;)
		// так как мы будем иметь такое же проевъю в шапке товара
		&--image {
			display: none;

			a {
				border: 1px solid transparent;
				display: block;
				max-width: 100%;
				transition: border-color 0.1s ease;
				&:hover{
					border-color: $c-grey;
				}
			}
			img {
				vertical-align: top;
			}

			@include media( $cart-mq-collapse ) {
				display: block;
				width: 150px;
				flex-shrink: 0;
				padding-left: 1em;
			}
		}

		// элемент с инфой, как правило - название и цены за единицу
		// блок "играет" и занимает оставшееся место от всех
		// прячется при схлопывании
		&--info {
			display: none;

			@include media( $cart-mq-collapse ) {
				display: block;
				flex-grow: 1;
			}
		}

		// контрол количества товара
		&--spinner {
			flex-shrink: 0;
			width: 60%;
			padding-bottom: 1em;
			border-bottom: 1px solid $cart-color-gray10;
			margin-bottom: 1em;

			@include media( $cart-mq-collapse ) {
				padding-left: 2em;
				width: auto;
				border-width: 0;
			}

			@include media( $cart-mq-collapse, max ) {
				.cart-item__label {
					display: none;
				}

			}
		}

		// дубляж цен за единицу из блока &--info
		// для использования при схлопывании с учетом
		// нового (визуального) порядка элементов
		&--prices {
			flex-shrink: 0;
			width: 40%;

			@include media( $cart-mq-collapse ) {
				display: none;
			}

			@include media( $cart-mq-collapse, max ) {
				padding-left: 0;
			}
		}

		// итоговые суммы по товару
		&--totals {
			flex-shrink: 0;
			width: 60%;

			@include media( $cart-mq-collapse ) {
				width: 180px;
			}
		}
	}

	// именнование товара
	&__title {
		margin-bottom: 1em;
		a {
			color: $c-blue;
			text-decoration: none;
			&:hover{
				color: $c-lblue;
			}
		}
	}

	// дополнительный заголовок
	// используется крайне редко и не поназначению )))
	// смотри ращметку шапки товара в шаблоне default
	&__sub-title {
		color: $cart-color-gray50;
	}

	&__head &__title {
		margin-bottom: .5em;
		line-height: 1.3em;
	}

	// подобие мини-заголовка
	// для подписей логических блоков
	&__label {
		display: block;
		margin-bottom: .4em;
		color: $cart-color-gray75;
		font-size: 1.2em;

		@include media( $cart-mq-collapse ) {
			font-size: 1em;
			margin-bottom: 1em;
		}
	}
}


// Открытие схлопнутового товара стилями
// при добавлении к основному элементу товара
// класса '.is-opened'
.cart-item.is-opened {

	@mixin isOpened() {
		// акцентируем внимание на открытом товаре
		// при помощи легкого градиента
		$color1: #fcfcfc;
		$color2: darken( $color1, 2% );
		background: linear-gradient(to bottom, $color1 0% , $color1 75%, $color2 100%);

		// меняем стилистику верхней границы
		&:before {
			border-top: 2px dotted $cart-color-gray50;
			left: 0;
			right: 0;
		}

		// меняем представление шапци товара
		.cart-item {
			// отделяем визуально
			&__head {
				padding-bottom: .5em;
				border-bottom: 1px solid $cart-color-gray10;
				margin-bottom: 1em;
			}

			// менеям направление "каретки" на закрытие
			&__opener {
				svg {
					&:first-child {
						display: none;
					}
					&:last-child {
						display: block;
					}
				}
			}

			&__short-info {
				// уваеличиваем название товара
				.cart-item__title {
					white-space: normal;
				}
				// убераем мелкую инфу,
				// она будет представлена в открытом теле товара
				.cart-item__sub-title {
					display: none;
				}
			}

			&__body {
				display: block;
			}
		}
	}

		@include media( $cart-mq-collapse, max ) {
			@include isOpened();
		}
	.cart--compact & {
		@include isOpened();
	}
}


// блок для цен товара
// имеет большое родительское влияние (место положения)
.cart-price {
	color: $cart-color-gray50;

	& + & {
		padding-top: .25em;
	}

	> * {
		display: inline-block;
	}

	// текст перед ценой,
	// как правило - Итого: 999...
	strong {
		font-size: 1.4em;
		font-weight: normal;
		margin-right: .2em;
	}

	// валюта, делаем меньше,
	// чтобы не акцентировать внимание на информацию, которую
	// пользователь и так знает, что на сайие все в гривнах или рублях или долларах
	small {
		font-size: 70%;
		margin-left: .2em;
		font-weight: normal;
		vertical-align: initial;
		color: $cart-color-gray50;
	}

	// НЕ старая цена
	&:not( &--old ) {

		.cart-div--emphasis & {
			color: $cart-color-black;
			small {
				color: $cart-color-black;
			}
		}

		span {
			font-weight: bold;
			font-size: 1.2em;

			@include media( $cart-mq-min ) {
				font-size: 1.6em;
			}

			.cart-item__cell--totals & {
				@include media( $cart-mq-min ) {
					font-size: 1.4em;
				}
			}


			.cart-div--emphasis & {
				color: $c-black;
			}

			.cart-results & {
				font-size: 1.8em;
			}
		}
	}

	// старая цена
	&--old {
		color: $cart-color-gray50;
		span {
			text-decoration: line-through;
		}
	}
}


// уведомление
.cart-notice {
	display: none;
	position: absolute;
	margin: -.75em 0 0 -1em;
	padding: .75em 1.5em 1em;
	border-right: .25em;
	background-color: #fff;
	z-index: 100;
	box-shadow: 0 2px 4px rgba( $cart-color-gray75, .5);

	.button {
		margin: 0 .3em .3em 0;
	}

	@include media( $cart-mq-middle ) {
		margin-left: -1.5em;
	}

	&__question {
		user-select: none;
		line-height: 1.4em;
		padding: .3em 0;
		margin-bottom: .25em;
	}

	&__answer {
		white-space: nowrap;
	}
}



// Открытие схлопнутового товара стилями
// при добавлении к основному элементу товара
// класса '.is-notice'
.cart-item.is-notice {
	.cart-notice,
	.cart-notice-overlay {
		display: block;
	}
}

// кнопки товара корзины
.cart-button {
	display: block;
	width: 2em;
	height: 2em;
	border-radius: .2em;
	text-align: center;
	cursor: pointer;
	background-color: transparent;
	padding: .5em;
	color: $cart-color-gray75;
	fill: currentColor;
	user-select: none;
	outline: none;
	border-width: 0;

	&.is-disabled {
		pointer-events: none;
		opacity: .4;
		background-color: $cart-color-gray10;
		color: $cart-color-gray50;
	}

	svg {
		max-width: 100%;
		max-height: 100%;
		pointer-events: none;
		fill: $c-dark;
		transition: fill 0.1s ease;

		+ svg {
			display: none;
		}
	}

	&:hover {
		color: $cart-color-gray75;
		background-color: $cart-color-gray10;
	}

	// аля outline
	&:active {
		svg{
			fill: $c-blue;
			transition: fill 0s ease;
		}
	}
}

// инпут в корзине,
// как правило - это инпут количества единиц каждого товара
.cart-input {
	display: block;
	width: 100%;
	height: 2em;
	text-align: center;
	border: 1px solid $cart-color-gray25;
	border-radius: .2em;
}

// общие характиристики размерностей
.cart-button,
.cart-input {
	font-size: 1.5em;

	@include media( $cart-mq-middle ) {
		font-size: 1.25em;
	}

	@include media( $cart-mq-collapse ) {
		font-size: 1em;
	}
}

// спиннер товара
.cart-spinner {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
	user-select: none;

	> * {
		flex-shrink: 0;
	}

	.cart-input {
		display: inline-flex;
		width: 2.5em;
		margin: 0 .25em;
		user-select: initial;
	}
}


// подвал корзины
.cart-footer {
	position: relative;
	border-top: 1px solid $cart-color-gray25;
}

// итоговые результаты корзины
.cart-results {
	padding: $cart-ver-min 0;
	text-align: center;
	flex-direction: column;

	@include media( $cart-mq-middle ) {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding-top: $cart-ver-max;
		flex-direction: row;
		padding-bottom: $cart-ver-max;
	}

	.cart--compact &,
	.cart--info & {
		display: block;
	}

	.cart-div--emphasis {

		@include media( $cart-mq-middle ) {
			margin-top: -$cart-ver-max;
			margin-bottom: -$cart-ver-max;
		}
		.cart-results__center &,
		.cart--compact &,
		.cart--info & {
			margin: 0 auto;
			padding: 1em;
			max-width: 320px;
		}
		.cart--compact & {
			margin-top: 1em;
		}
	}

	&__left {
		@include media( $cart-mq-middle, max ) {
			display: inline-block;
			min-width: 60%;
			margin-bottom: 1em;
			.button {
				font-size: 1.25em;
			}
		}
	}

	&__right {
		@include media( $cart-mq-middle, max ) {
			display: inline-block;
			min-width: 60%;
			.cart-price span {
				font-size: 2em;
			}
			.button {
				font-size: 1.25em;
			}
		}
	}
}

// блок для прелоадера корзины
.cart__loader {
	text-align: center;
	position: relative;
	margin: 1rem;

	&:before {
		content: '';
		display: block;
		width: 160px;
		height: 20px;
		background-image: url(pic/cart-loader-container.gif);
		margin: 0 auto 1rem;
	}
}