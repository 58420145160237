.enter-reg{
	display: flex;
	justify-content: space-between;
	position: relative;
	flex-wrap: wrap;

	@include media($defp){
		flex-wrap: nowrap;
	}

	&__title{
		margin-bottom: 36px;
		font-size: 21px;
		font-weight: 300;
		font-family: 'Museo Sans';
	}
	.order-content__bucklets{
		.control-holder{
			width: 100%;
		}
	}
}

.enter-block{
	width: 100%;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	margin-bottom: 14px;
	visibility: hidden;
	height: 100%;
	transition: opacity 0.2s ease, visibility 0s 0.2s ease;
	
	@include media($defp){
		width: calc(50% - 10px);
		margin-bottom: 0;
	}
	&.is-active{
		visibility: visible;
		opacity: 1;
		position: relative;
		transition: opacity 0.1s 0.1s ease, visibility 0s ease;
	}
	&__form{
		padding: 18px;
		height: auto;
		border: 1px solid $c-grey3;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		.form__group{
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: left;

			@include media($defp){
				align-items: center;
				flex-direction: row;
			}
		}
		.form__caption{
			width: 150px;
			font-weight: 300;
			font-family: 'Museo Sans';
			font-size: 15px;
			color: $c-black;
		}
		.control-holder{
			width: 100%;

			@include media($md){
				width: calc(100% - 150px);
			}
		}
		.lose-password,
		.button{
			margin-left: 0;

			@include media($defp){
				margin-left: 150px;
			}
		}
	}
}

.repassword-block{
	width: 100%;
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	visibility: hidden;
	height: 100%;
	margin-bottom: 14px;
	transition: opacity 0.2s ease, visibility 0s 0.2s ease;

	@include media($defp){
		width: calc(50% - 10px);
		margin-bottom: 0;
	}
	&.is-active{
		visibility: visible;
		opacity: 1;
		position: relative;
		transition: opacity 0.1s 0.1s ease, visibility 0s ease;
	}
	&__form{
		padding: 18px;
		height: auto;
		border: 1px solid $c-grey3;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		.form__group{
			width: 100%;

			@include media($md){
				width: 376px;
			}
		}
		.form__caption{
			font-weight: 300;
			font-family: 'Museo Sans';
			font-size: 15px;
			color: $c-black;
		}
	}
	&__buttons{
		display: flex;
		justify-content: space-between;
		width: 100%;
		flex-wrap: wrap;

		@include media($md){
			width: 376px;
			flex-wrap: nowrap;
		}
	}
}

.reg-block{
	width: 100%;
	display: block;
	height: 100%;
	@include media($defp){
		width: calc(50% - 10px);
	}
	&__form{
		padding: 18px;
		height: auto;
		border: 1px solid $c-grey3;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		.form__group{
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: left;

			@include media($defp){

				align-items: center;
			}
			@include media($md){
				flex-direction: row;
			}
		}
		.form__caption{
			width: 150px;
			font-weight: 300;
			font-family: 'Museo Sans';
			font-size: 15px;
			color: $c-black;
		}
		.control-holder{
			width: 100%;

			@include media($md){
				width: calc(100% - 150px);
			}
		}
		.button{
			margin-left: 0;

			@include media($defp){
				margin-left: 150px;
			}
		}
	}
}

.lose-password{
	margin-bottom: 40px;
	cursor: pointer;
	transition: color 0.1s ease;
	font-size: 14px;
	font-weight: 300;
	width: 136px;
	font-family: 'Museo Sans';
	display: inline-block;
	&:hover{
		color: $c-lblue;
	}
}