.web-service{
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: column;
	align-items: center;
	@include media($def){
		justify-content: space-between;
	}
	@include media($sm){
		flex-direction: row;
	}
	&__item{
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-bottom: 20px;
		@include media($def){
			flex-direction: row;
		}
		@include media($sm){
			margin-bottom: 0;
		}
		&:nth-child(2){
			width: 270px;
		}
		&:nth-child(1){
			width: 200px;
		}
	}
	&__text{
		color: $c-bgrey;
		text-transform: uppercase;
		font-size: 10px;
		line-height: 14px;
		letter-spacing: 0.1em;
		font-weight: 300;
		font-family: 'Museo Sans';
		margin-left: 0;
		margin-bottom: 10px;
		order: 1;
		@include media($def){
			order: 2;
			margin-left: 20px;
			margin-bottom: 0;
		}
	}
}
.elit_web{
	width: 142px;
	height: 44px;
	flex-shrink: 0;
	order: 2;
	@include media($def){
		order: 1;
	}
}
.wezom{
	text-decoration: none;
	display: flex;
	align-items: center;
	align-content: center;
	flex-shrink: 0;
	order: 2;
	@include media($def){
		order: 1;
	}
	&__text{
		font-weight: bold;
		font-size: 14px;
		letter-spacing: 0.05em;
		text-decoration: none;
		color: #1e1e1e;
		transition: color 0.1s ease;
	}
	&__logo-holder{
		margin-right: 10px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		width: 34px;
		height: 34px;
		transition: border-radius 1s ease;
		background-color: #c60d0f;
		svg{
			width: 28px;
			height: 10px;
			fill: $c-white;
		}
	}
	&:hover{
		.wezom__logo-holder{
			border-radius: 100%;
		}
		.wezom__text{
			color: #c60d0f;
		}
	}
}